import React, {RefObject} from 'react';
import {StyleProp} from 'react-native';
import PropTypes from 'prop-types';
import {TranslateFunctionInterface} from '@/types';

// Typing

export type CommonProps = {
  style?: StyleProp<any>;
  navigation?: any;
  route?: {key: string; name: string; params: any};
  ref?: RefObject<any>;
};

export type ContextType = {t: TranslateFunctionInterface};

export type WatchOptionsType = {
  truthy?: boolean;
};

// Component
export default abstract class Component<P = {}, S = {}> extends React.Component<P & CommonProps, S> {
  context!: ContextType;
  watches: {[key in keyof P]?: {callback: (newValue: any, oldValue: any) => void; value: any; options: WatchOptionsType}} = {};

  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props: P & CommonProps) {
    super(props);
  }

  getFakeContext() {
    return {t: this.context.t};
  }
}
