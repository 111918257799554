import {ScrollView, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import React, {FC} from 'react';
import {Spacing} from '@styles';

type Props = {
  stepIndicator?: {
    stepCount: number;
    currentPosition: number;
  };
  style?: StyleProp<ViewStyle>;
};

const FullScreenContainer: FC<Props> = props => (
  <View style={[FullScreenContainerStyles.SafeAreaView, props.style]}>
    <ScrollView contentContainerStyle={FullScreenContainerStyles.loginContentContainerStyle}>
      <View style={{flex: 1}}>{props.children}</View>
    </ScrollView>
  </View>
);

export default FullScreenContainer;

const FullScreenContainerStyles = StyleSheet.create({
  SafeAreaView: {
    flex: 1,
    ...Spacing.Margins.loginContainer,
  },
  loginContainer: {
    flex: 1,
    ...Spacing.Margins.loginContainer,
  },
  loginContentContainerStyle: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    justifyContent: 'flex-end',
    ...Spacing.Paddings.loginContainer,
  },
  loginSteps: {
    minHeight: 60,
    justifyContent: 'flex-start',
  },
});
