import Repository from './Repository';

export default {
  create(question, answers, participants, votation_type, date_start, date_finish, follow_link, reminder, multiQuestions, type, email, password, host, port,location) {
    return Repository.new().post('/create-votation', {question, answers, participants, votation_type, date_start, date_finish, follow_link, reminder, multiQuestions, type, email, password, host, port,location});
  },
  vote(votation, answer, voter, delegatedVoter, delegatedAnswer, token, type) {
    console.log(voter);
    return Repository.new().post('/vote', {votation, answer, voter, delegatedVoter, delegatedAnswer, token, type});
  },
  confirm_vote(id_vote, voter, answer = null) {
    return Repository.new().post('/confirm-vote', {id_vote, voter, answer});
  },
  edit_votation(votation, email) {
    return Repository.new().post('/edit-votation', {votation, email});
  },
  getVote(id) {
    return Repository.new().get('/get-vote', {id});
  },
  getVotation(id) {
    return Repository.new().get('/get-votation', {id});
  },
  getUser(id) {
    return Repository.new().get('/get-user', {id});
  },
  results(votation, result) {
    return Repository.new().get('/results', {votation, result});
  },
  getAllVotes(begin, end) {
    return Repository.new().post('/get-all-votes', {begin, end});
  },
  saveConfiguration(regular_percentage, incentivized_percentage) {
    return Repository.new().post('/save-configuration', {regular_percentage, incentivized_percentage});
  },
  getConfiguration() {
    return Repository.new().get('/get-configuration', {});
  },
  testEmail(mail, pass, host, port) {
    return Repository.new().post('/test-email', {mail, pass, host, port})
  },
  getMapViewMarkerInformation() {
    return Repository.new().get('/map-view-marker-information', {});
  },
};
