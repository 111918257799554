import {SOCKET_ACTIONS} from '@store/actionTypes';

export function setSocket(socket: any) {
  return {
    type: SOCKET_ACTIONS.SET_SOCKET as const,
    socket,
  };
}

export type SOCKET_ACTIONS_TYPES = ReturnType<typeof setSocket>;
