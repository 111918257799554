import React from 'react';
import {Component} from '@lib_components';
import {FlatList, Text, StyleSheet, TouchableHighlight, View, Dimensions, TextStyle} from 'react-native';
import {RootState} from '@store/root';
import {getUser} from '@reducers/user/selectors';
import {bindActionCreators, Dispatch} from 'redux';
import {resetUserInformation, setUser} from '@reducers/user/actions';
import {connect} from 'react-redux';
import {Card} from 'react-native-paper';
import Icon from 'react-native-vector-icons/FontAwesome';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import NavigationService from '@navigations/NavigationService';
import Emitter from '@utils/Emitter';
import {SCREENS} from '@navigations/screens';
import ApiRepository from '@/repositories/ApiRepository';
import LocalRepository from '@/repositories/LocalRepository';
import {number} from 'prop-types';
import {Button} from '@material-ui/core';

// Typing
type OwnProps = {};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {
  incentivizedPercentage: Number;
  regularPercentage: Number;
};

class Configuration extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      incentivizedPercentage: 50,
      regularPercentage: 50,
    };
  }

  async checkLogged() {
    const token = await LocalRepository.get('token');
    if (!token) NavigationService.navigate(SCREENS.Admin);
  }

  async componentDidMount() {
    await this.checkLogged();
    ApiRepository.getConfiguration().then(res => {
      if (res.data.configuration) {
        this.setState({
          incentivizedPercentage: Number(res.data.configuration.incentivized_percentage),
          regularPercentage: Number(res.data.configuration.regular_percentage),
        });
      }
    });
  }

  save() {
    ApiRepository.saveConfiguration(this.state.regularPercentage, this.state.incentivizedPercentage)
      .then(() => {
        Emitter.emit('show-snackbar', {message: 'Configuració guardada correctament!', color: 'success'});
      })
      .catch(err => {
        Emitter.emit('show-snackbar', {message: 'Error guardant la configuració saving percentage!', color: 'danger'});
      });
  }

  render() {
    return (
      <View style={{alignSelf: 'flex-end', maxWidth: Dimensions.get('window').width, marginTop: 40, width: '90%', margin: 'auto'}}>
        <Card>
          <Card.Title title="Configuració" style={{alignSelf: 'center'}} subtitle="" />
          <Card.Content>
            <View style={{flex: 1, flexDirection: 'row'}}>
              <View style={{flex: 1, paddingRight: 40}}>
                <TextField
                  id="incentivizedPercentage"
                  label="Percentatge de vots incentivats"
                  type="number"
                  value={this.state.incentivizedPercentage}
                  onChange={date => {
                    const {value} = date.target;
                    this.setState({incentivizedPercentage: Number(value), regularPercentage: 100 - Number(value)});
                  }}
                />
              </View>
              <View style={{flex: 1, paddingRight: 20}}>
                <TextField
                  id="regularPercentage"
                  label="Percentatge de vots normals"
                  type="number"
                  value={this.state.regularPercentage}
                  onChange={date => {
                    const {value} = date.target;
                    this.setState({regularPercentage: Number(value), incentivizedPercentage: 100 - Number(value)});
                  }}
                />
              </View>
            </View>
            <div style={{margin: 15, textAlign: 'center'}}>
              <div>
                <Button style={{backgroundColor: '#cd5360', color: 'white'}} onClick={() => this.save()}>
                  Guardar
                </Button>
              </div>
            </div>
          </Card.Content>
        </Card>
      </View>
    );
  }
}

// Redux connect

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetUserInformation,
      setUser,
    },
    dispatch
  );

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
  },
});

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Configuration);
