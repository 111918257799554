import React from 'react';
import {Component} from '@lib_components';
import {ButtonTypeMap, CircularProgress} from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import {ButtonBaseTypeMap} from '@material-ui/core/ButtonBase/ButtonBase';

export type Props = {href?: string; loading?: boolean; onClick?: () => void} & ButtonBaseTypeMap['props'] & ButtonTypeMap['props'];

export default class Button extends Component<Props> {
  render() {
    let {color, variant} = this.props;
    if (!color) {
      color = 'primary';
    }
    if (!variant) {
      variant = 'contained';
    }
    const LoadingComponent = <CircularProgress size={25} style={{color: 'white'}} />;
    return (
      <MaterialButton {...this.props} style={{marginBottom: 10, ...this.props.style}} color={color} variant={variant}>
        {this.props.loading ? LoadingComponent : this.props.children}
      </MaterialButton>
    );
  }
}
