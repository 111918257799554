import React, {RefObject} from 'react';
import {Component} from '@lib_components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// @ts-ignore
import {mdiCheckboxMarked, mdiCalendarToday, mdiHelpCircle} from '@mdi/js';
import Icon from '@mdi/react';
import {StyleSheet, View, Text} from 'react-native';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

type Props = {
  responses: Array<string>;
  emails: Array<any>;
  phones: Array<any>;
  question: string;
  // date: Date;
  dateStart: string;
  dateEnd: string;
  incentivized: boolean;
  chboxFollowLink: boolean;
  questions: Array<{
    question: string;
    answers: any[];
  }>;
};

type State = {
  color: string;
};

// Component
export default class AnswersComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      color: 'grey',
    };
  }

  _renderEmails() {
    return this.props.emails.map(data => (
      <Chip avatar={<Avatar style={{backgroundColor: '#cd5360', borderColor: '#cd5360', color: 'black'}}>{data.substring(0, 1).toUpperCase()}</Avatar>} label={data} />
    ));
  }

  _renderPhones() {
    return this.props.phones.map(data => (
      <Chip avatar={<Avatar style={{backgroundColor: '#cd5360', borderColor: '#cd5360', color: 'black'}}>{data.substring(0, 1).toUpperCase()}</Avatar>} label={data} />
    ));
  }

  _renderAnswers() {
    return this.props.questions.map((row, i) => (
      <View style={{marginBottom: 15}}>
        <div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Icon style={{alignItems: 'flex-start', alignSelf: 'center'}} path={mdiHelpCircle} color={this.state.color} size={1.3} />
            <Text style={{alignSelf: 'center'}}>{row.question}</Text>
          </div>
          {row.answers.map((response, index) => (
            <View style={{marginLeft: 20}}>
              <div>
                <ListItem button style={{padding: 0}}>
                  <ListItemIcon>
                    <Icon path={mdiCheckboxMarked} color={this.state.color} size={1.3} />
                  </ListItemIcon>
                  <ListItemText primary={response} />
                </ListItem>
              </div>
            </View>
          ))}
          <View>
            <div style={{marginLeft: 20}}>
              <ListItem button style={{padding: 0}}>
                <ListItemIcon>
                  <Icon path={mdiCheckboxMarked} color={this.state.color} size={1.3} />
                </ListItemIcon>
                <ListItemText primary="Vot en blanc" />
              </ListItem>
            </div>
          </View>
        </div>
      </View>
    ));
  }

  render() {
    return (
      <View>
        <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', flexWrap: 'wrap'}}>
          {(this.props.emails.length !== 0 && this.props.phones.length !== 0 ) && (<Text style={{alignItems: 'flex-start', alignSelf: 'center'}}>{this.context.t('Participants:')}</Text>)}
          {this.props.emails.length !== 0 && (
            <Paper style={{boxShadow: 'none', padding: 0}} component="ul">
              {this._renderEmails()}
            </Paper>
          )}
          {this.props.phones.length !== 0 && (
            <Paper style={{boxShadow: 'none', padding: 0}} component="ul">
              {this._renderPhones()}
            </Paper>
          )}
        </div>
        <List component="nav" aria-label="main mailbox folders" style={{padding: 0}}>
          {this._renderAnswers()}
          <ListItem button>
            <ListItemIcon>
              <Icon path={mdiCalendarToday} color={this.state.color} size={1.3} />
            </ListItemIcon>
            <ListItemText primary={moment(this.props.dateStart).format('YYYY-MM-DD hh:mm')} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Icon path={mdiCalendarToday} color={this.state.color} size={1.3} />
            </ListItemIcon>
            <ListItemText primary={moment(this.props.dateEnd).format('YYYY-MM-DD hh:mm')} />
          </ListItem>
        </List>
      </View>
    );
  }
}

const styles = StyleSheet.create({});
