/* eslint-env browser */

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register('../expo-service-worker.js', {scope: '/'})
      .then(function (registration) {
        console.info('Registered service-worker', registration);
      })
      .catch(function (error) {
        console.info('Failed to register service-worker', error);
      });
  });
}
