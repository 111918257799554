import React, {Component} from 'react';
import {View, Text} from 'react-native';

type Props = {};

type State = {};

class Footer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <View>
        <Text style={{fontSize: 17}}>Vote-Vote © 2021</Text>
      </View>
    );
  }
}

export default Footer;
