import React from 'react';
import {Component} from '@lib_components';
import {H3} from '@/components/atoms/index';

type Props = {
  title: string;
};

type State = {};

export default class HeaderTitle extends Component<Props, State> {
  render() {
    return <H3>{this.props.title}</H3>;
  }
}
