module.exports = {
  // BASE_URL: 'http://localhost:3032',
  // LOCAL_URL: 'http://localhost:3032',
  // BASE_CLIENT_URL: 'http://localhost:19006',
  // LOCAL_CLIENT_URL: 'http://localhost:19006',
  BASE_URL: 'https://votevote.centreeasy.com',
  LOCAL_URL: 'https://votevote.centreeasy.com',
  BASE_CLIENT_URL: 'https://votevote.centreeasy.com',
  LOCAL_CLIENT_URL: 'https://votevote.centreeasy.com',
  USER: {
    ROLE_ADMIN: 1,
    ROLE_USER: 2,
  },
  ERRORS: {
    USER_NOT_FOUND: 404,
    ERROR_GENERIC: 499,
    ERROR_USER_NOT_FOUND: 500,
    ERROR_USER_ALREADY_VALIDATED: 509,
  },
  VOTATION: {
    REGULAR_VOTATION: 0,
    ENCOURAGED_VOTATION: 1,
  },
  TYPES: {
    EMAIL: 0,
    PHONE: 1,
    NO_CENSUS: 2,
    GEOLOCATED: 3,
  },
  VOTE_STATUS: {
    PENDING: 1,
    CONFIRMED: 2,
    DENIED: 3,
  },
};
