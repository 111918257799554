import React from 'react';

import {Component} from '@lib_components';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import {StyleSheet, View, Text, Image, ImageStyle, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import {Button, createStyles, StepIconProps, Theme} from '@material-ui/core';

type Props = {
  selectedEmails: (emails: any) => void;
  emails: Array<string>;
};

type State = {
  emails: Array<string>;
  email: string;
  errorEmail: boolean;
  emailEmpty: boolean;
  files: any;
};

// Component
export default class MultipleEmailSelect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emails: [],
      email: '',
      errorEmail: false,
      emailEmpty: false,
      files: null,
    };
  }

  componentDidMount() {
    if (this.props.emails.length > 0) {
      this.setState(prevState => ({...prevState, emails: this.props.emails}));
    }
  }

  handleDelete(data: string) {
    this.state.emails.splice(this.state.emails.indexOf(data), 1);
    this.setState(prevState => ({...prevState, emails: prevState.emails}));
  }

  _renderEmails() {
    return this.state.emails.map(data => (
      <Chip
        avatar={<Avatar style={{backgroundColor: '#cd5360', borderColor: '#cd5360', color: 'black'}}>{data.substring(0, 1).toUpperCase()}</Avatar>}
        label={data}
        onDelete={() => this.handleDelete(data)}
      />
    ));
  }

  emailValidate(value: string) {
    if (value === '') return true;
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value);
  }

  find(email: string) {
    return this.state.emails.find(value => email === value);
  }

  addChip(emailsFile: string[] | null = null) {
    const emails = emailsFile || this.state.email.split(' ').join(',').split(',');
    console.log(emails);
    for (const email of emails) {
      if (this.emailValidate(email) && email !== '' && !this.find(email)) {
        this.state.emails.push(email);
      }
    }
    this.setState(() => ({email: ''}));
    this.setState(prevState => ({files: null}));
    this.props.selectedEmails(this.state.emails);
    this.empty();
  }

  empty() {
    this.setState(prevState => ({emailEmpty: prevState.emails.length < 2}));
  }

  rules(value: string) {
    this.setState(prevState => ({errorEmail: !this.emailValidate(value)}));
  }

  async pickImage(e: any, vm: any) {
    const {files} = e.target;
    vm.setState((prevState: any) => ({...prevState, files}));
    this.readFile(files);
  }

  readFile(files: any) {
    const reader = new FileReader();
    reader.onload = r => {
      const textFile = r.target?.result;
      const emails = textFile
        ?.toString()
        .split('\n')
        .map(email => email.trim());
      this.addChip(emails);
    };
    reader.readAsText(files[0]);
  }

  render() {
    return (
      <View>
        <Paper style={{boxShadow: 'none', padding: 0, alignSelf: 'center'}} component="ul">
          {this._renderEmails()}
        </Paper>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <TextField
              value={this.state.email}
              label=""
              required
              error={this.state.emailEmpty}
              onChange={e => {
                // @ts-ignore
                const {value} = e.target;
                this.empty();
                this.setState(prevState => ({...prevState, email: value}));
              }}
              helperText="Introdueix un mínim de dos participants."
              onKeyPress={event => event.charCode == 13 && this.addChip()}
            />
          </View>

          <View style={{backgroundColor: '#cd5360', marginLeft: 12, marginTop: 20, borderRadius: 10}}>
            <form>
              <label htmlFor="input">
                <View>
                  <TouchableOpacity style={{flexDirection: 'row', height: 35, alignItems: 'center'}}>
                    <input style={{color: 'transparent', display: 'none'}} type="file" accept=".csv,.txt" id="input" onChange={(event: any) => this.pickImage(event, this)} />
                    <Icon name="upload" size={20} color="white" style={{marginRight: 10, marginLeft: 10, marginTop: 20, marginBottom: 20}} />
                  </TouchableOpacity>
                </View>
              </label>
            </form>
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  chip: {
    margin: 10,
  },
});
