import React from 'react';
import {StyleSheet, Text as NativeText} from 'react-native';
import {Component} from '@lib_components';
import {Colors, Spacing, Typography} from '@/styles';

type Props = {
  white?: boolean;
};

type State = {};

const TextStyles = StyleSheet.create({
  base: {
    ...Typography.Text,
    ...Spacing.Text,
    color: Colors.normalTextColors.text,
  },
});

export default class Text extends Component<Props, State> {
  render() {
    const WhiteStyle = this.props.white ? {color: Colors.white} : {};
    return <NativeText style={[TextStyles.base, WhiteStyle, this.props.style]}>{this.props.children}</NativeText>;
  }
}
