import React, {Component} from 'react';
import {Dimensions, StyleSheet, Text, TextStyle, View} from 'react-native';
import FullScreenContainer from '@layouts/FullScreenContainer';
// @ts-ignore
import {mdiCheckCircle} from '@mdi/js';
import Icon from '@mdi/react';
import {Button, TextField} from '@material-ui/core';
import ApiRepository from '@/repositories/ApiRepository';
import NavigationService from '@navigations/NavigationService';
import {SCREENS} from '@navigations/screens';
import Emitter from '@utils/Emitter';
import Constants from '@/constants';

const Config = require('../config/config');

type Props = {
  route: any;
};

type State = {
  emailOwner: string;
  emailError: {
    error: boolean;
    message: string;
  };
};

class SnackBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emailOwner: '',
      emailError: {
        error: false,
        message: '',
      },
    };
  }

  _handleClick() {
    ApiRepository.edit_votation(this.props.route.params.votation_id, this.state.emailOwner)
      .then(() => {
        Emitter.emit('show-snackbar', {message: "El vostre correu electrònic s'ha registrat! Estigueu atents!", color: 'success'});
        NavigationService.navigate(SCREENS.Home);
      })
      .catch(err => {
        console.log(err);
      });
  }

  goToResults() {
    const {results_id} = this.props.route.params;
    NavigationService.navigate(SCREENS.Results, {results_id});
  }

  _hasEmailError(email: string) {
    if (email === '') return true;
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
  }

  _disableButton() {
    return !this._hasEmailError(this.state.emailOwner) || this.state.emailOwner === '';
  }

  isNotMobileScreen() {
    return Dimensions.get('window').width > 500;
  }

  generateLink() {
    let base_url = '';
    if (Config.is_develop) {
      base_url = Constants.LOCAL_CLIENT_URL;
    } else {
      base_url = Constants.BASE_CLIENT_URL;
    }
    return `${base_url}/votation?votation_id=${this.props.route.params.votation_id}&type=${this.props.route.params.type}`;
  }

  render() {
    return (
      <FullScreenContainer style={{margin: 0, backgroundColor: 'white'}}>
        <View
          style={{
            display: 'flex',
            width: this.isNotMobileScreen() ? 'max-content' : undefined,
            backgroundColor: '#00B65A',
            paddingTop: 20,
            paddingBottom: 20,
            alignSelf: 'center',
          }}>
          <View style={{flexDirection: 'row', flexShrink: undefined}}>
            <View style={{padding: 15, alignSelf: 'center'}}>
              <Icon path={mdiCheckCircle} color="#00813F" size={1.3} />
            </View>
            {/* @ts-ignore */}
            <View style={{alignSelf:"center",flexShrink: 'initial', paddingLeft: 10, width: '100%', textAlign: 'center', paddingRight: this.isNotMobileScreen() ? 20 : 10} as TextStyle}>
              {/* eslint-disable-next-line max-len */}
              {(this.props.route.params.type != 2 && this.props.route.params.type != 3) &&(<Text style={[styles.text, {paddingBottom: 20}]}>Els correus electrònics han estat enviats satisfactoriament!</Text>)}
              <Text style={[styles.text]}>
                Podeu realitzar el seguiment de les votacions al següent enllaç o escriure el vostre correu electrònic i l&apos;enllaç serà enviat a la vostra safata
                d&apos;entrada.
              </Text>
            </View>
          </View>
        </View>
        <View style={{alignItems: 'center', marginTop: 20}}>
          <Button style={{width: 'max-content', backgroundColor: '#cd5360', color: 'white', padding: 10}} onClick={() => this.goToResults()}>
            Seguiment de la votació
          </Button>
        </View>
        <View style={{alignItems: 'center', marginTop: 20}}>
          {(this.props.route.params.type !== Constants.TYPES.EMAIL) && (
            <Text>
              Enllaç de votació: <a href={this.generateLink()}>{this.generateLink()}</a>
            </Text>
          )}
          <TextField
            label="Envia enllaç de seguiment"
            style={{marginTop: 20}}
            value={this.state.emailOwner}
            error={this.state.emailError.error}
            helperText={this.state.emailError.message}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              // @ts-ignore
              const {value} = e.target;
              if (!this._hasEmailError(value)) {
                this.setState(prevState => ({...prevState, emailOwner: value, emailError: {error: true, message: 'Format de correu electrònic incorrecte'}}));
              } else {
                this.setState(prevState => ({...prevState, emailOwner: value, emailError: {error: false, message: ''}}));
              }
            }}
          />
          <Button
            style={{width: 'max-content', backgroundColor: this._disableButton() ? 'lightgray' : '#cd5360', color: 'white', padding: 10}}
            onClick={() => this._handleClick()}
            disabled={this._disableButton()}>
            Enviar
          </Button>
        </View>
      </FullScreenContainer>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    color: 'white',
    fontSize: 17,
  },
});

export default SnackBar;
