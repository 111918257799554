import {margin, padding, scaleSize} from './mixins';

export const SCALE_30 = scaleSize(30);
export const SCALE_20 = scaleSize(20);
export const SCALE_10 = scaleSize(10);

export const Margins = {
  loginContainer: {
    ...margin(10, 10, 10, 10),
  },
};

export const Paddings = {
  loginContainer: {
    ...padding(0, 0, SCALE_20),
  },
  appContiner: {
    ...padding(0, SCALE_20),
  },
};

export const H1 = {
  ...margin(0, 0, SCALE_20, 0),
};

export const H2 = {
  ...margin(0, 0, SCALE_20, 0),
};

export const H3 = {
  ...margin(0, 0, SCALE_10, 0),
};

export const H4 = {
  ...margin(0, 0, SCALE_10, 0),
};

export const H5 = {
  ...margin(0, 0, SCALE_10, 0),
};

export const Text = {
  ...margin(0, 0, SCALE_10, 0),
};

export const noMargin = {
  ...margin(0),
};
