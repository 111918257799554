import {Dimensions} from 'react-native';

export const MAX_WINDOW_WIDTH = 420;
export const MAX_WINDOW_HEIGHT = 850;

export const WINDOW_WIDTH = Dimensions.get('window').width > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH : Dimensions.get('window').width;
export const WINDOW_HEIGHT = Dimensions.get('window').height > MAX_WINDOW_HEIGHT ? MAX_WINDOW_HEIGHT : Dimensions.get('window').height;

const guidelineBaseWidth = MAX_WINDOW_WIDTH;

export const scaleSize = (size: number) => (WINDOW_WIDTH / guidelineBaseWidth) * size;
export const scaleFont = (size: number) => (WINDOW_WIDTH / guidelineBaseWidth) * size;

// New type for dimensions
type DimensionUnit = number | string;

function dimensions(top: DimensionUnit, right: DimensionUnit, bottom: DimensionUnit, left: DimensionUnit, property: string) {
  const styles: Record<string, DimensionUnit> = {};

  styles[`${property}Top`] = top;
  styles[`${property}Right`] = right;
  styles[`${property}Bottom`] = bottom;
  styles[`${property}Left`] = left;

  return styles;
}

export function margin(top: DimensionUnit, right: DimensionUnit = top, bottom: DimensionUnit = top, left: DimensionUnit = right) {
  return dimensions(top, right, bottom, left, 'margin');
}

export function padding(top: DimensionUnit, right: DimensionUnit = top, bottom: DimensionUnit = top, left: DimensionUnit = right) {
  return dimensions(top, right, bottom, left, 'padding');
}

export function boxShadow(color: string, offset = {height: 2, width: 2}, radius = 8, opacity = 0.2) {
  return {
    shadowColor: color,
    shadowOffset: offset,
    shadowOpacity: opacity,
    shadowRadius: radius,
    elevation: radius,
  };
}
