export const translations = {
  en: {
    Captain: '',
    Police: '',
    Marpol: '',
    'Port Authority': '',
    Traceability: '',
    Reset: '',
    'Houston, we have a problem!': 'Houston, we have a problem!',
    "That's was unexpected... let me try again.": 'This doesn’t look good… try again.',
    'Back to Home': 'Back to home page',
  },
  options: {
    plural_rule: 'n != 1',
    plural_number: '2',
  },
  es: {
    Captain: '',
    Police: '',
    Marpol: '',
    'Port Authority': '',
    Traceability: '',
    Reset: '',
    'Houston, we have a problem!': '¡Houston, tenemos un problema!',
    "That's was unexpected... let me try again.": 'Esto no pinta bien... inténtalo de nuevo.',
    'Back to Home': 'Volver a la página principal',
  },
};
