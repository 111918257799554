import {SOCKET_ACTIONS_TYPES} from '@reducers/socket/actions';
import {SOCKET_ACTIONS} from '@store/actionTypes';
import socketIOClient from 'socket.io-client';
import Constants from '@/constants';

// State Type
type ReducerState = Readonly<{
  socket: any;
}>;

// Initial state
const initialState: ReducerState = {
  socket: socketIOClient(Constants.BASE_URL, {path: '/socket'}),
};

// Actions that modify the state
export default (stateIn: ReducerState, action: SOCKET_ACTIONS_TYPES): ReducerState => {
  const state = stateIn || initialState;
  switch (action.type) {
    case SOCKET_ACTIONS.SET_SOCKET:
      return {...state, socket: action.socket};
  }
  return state;
};
