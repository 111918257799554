export enum SCREENS {
  Home = 'home',
  Results = 'results',
  Menu = 'menu',
  Votacions = 'admin-results',
  Admin = 'admin',
  Vote = 'votation',
  CreateVote = 'CreateVote',
  PostCreationVotation = 'post-creation-votation',
  PublicResults = 'public-results',
  Map = 'Map',
}
