import {SafeAreaView, StyleSheet, View} from 'react-native';
import React, {Component} from 'react';
import {Colors, Spacing} from '@styles';
import {CircularProgress} from '@material-ui/core';

// Typing

type Props = {};

type State = {};

export default class LoadingContainer extends Component<Props, State> {
  render() {
    return (
      <SafeAreaView style={[LoadingContainerStyles.SafeAreaView]}>
        <View style={LoadingContainerStyles.container}>
          <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress size={200} />
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

const LoadingContainerStyles = StyleSheet.create({
  SafeAreaView: {
    flex: 1,
    backgroundColor: Colors.background,
  },

  container: {
    ...Spacing.Margins.loginContainer,
    flex: 2,
    justifyContent: 'center',
  },
});
