import React, {Component} from 'react';
import {View, Text} from 'react-native';
import {RootState} from '@store/root';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {setSocket} from '@reducers/socket/actions';
import {getSocket} from '@reducers/socket/selectors';
import {getUser} from '@reducers/user/selectors';
import NavigationService from '@navigations/NavigationService';
import SnackBar from '@components/Snackbar';
import Footer from '@scenes/Footer';

type OwnProps = {
  children: any;
};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {};

class MainLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    NavigationService.setReadyRef(false);
    // if (!this.props.socket) {
    //     this.props.setSocket(socketIOClient(Constants.BASE_URL, {path: '/socket'}));
    // }
    // this.props.socket.on('CONNECTION_SOCKET', async (data: any) => {
    //     await LocalRepository.set('connectionSocket', data);
    //     if (data && this.props.user && this.props.user.token) {
    //         AuthRepository.saveSocket(data).then().catch();
    //     }
    // });
  }

  render() {
    return (
      <View style={{height: '100%', backgroundColor: 'white'}}>
        <View
          style={{
            width: '100%',
            height: '98%',
            backgroundColor: 'white',
            marginBottom: 20,
          }}>
          {this.props.children}
        </View>

        <View style={{position: 'absolute', bottom: 0, alignItems: 'center', height: '3%', width: '100%'}}>
          <Footer />
        </View>
        <SnackBar />
      </View>
    );
  }
}

// Redux connect
const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({socket: getSocket(state), user: getUser(state)});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({setSocket}, dispatch);

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(MainLayout);
