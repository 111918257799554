import {ALL_ACTIONS} from '@store/actionTypes';
import {Action, AnyAction, applyMiddleware, combineReducers, compose, createStore, Reducer} from 'redux';
import {i18nState} from 'redux-i18n';
import thunk from 'redux-thunk';
import I18NReducer from '@reducers/i18n/reducer';
import {getFakeContext} from '@reducers/i18n/selectors';
import {FakeContextType} from '@/types';
import Config from '@config/config';
import {persistReducer, persistStore} from 'redux-persist';
import SocketReducer from '@reducers/socket/reducer';
import UserReducer from '@reducers/user/reducer';
import storage from 'redux-persist/lib/storage';

type ReducerState<T extends Record<string, (...args: any) => any>> = {[K in keyof T]: ReturnType<T[K]>};

/**
 * All reducers goes here
 */
const reducers = {
  i18n: I18NReducer,
  socket: SocketReducer,
  user: UserReducer,
  i18nState,
};

export type RootState = ReducerState<typeof reducers>;

export type GetRootState = () => RootState;

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers(reducers);

// Create Store
let storeReducer: Reducer;
if (Config.persistStore) {
  storeReducer = persistReducer(
    {
      key: 'root',
      storage,
    },
    rootReducer
  );
} else {
  storeReducer = rootReducer;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(storeReducer, composeEnhancers(applyMiddleware(thunk)));

/**
 * Redux Action
 */

interface FixedDispatch<A extends Action = AnyAction> {
  <T extends A>(action: T | ReduxActionRet): Promise<T> | T;
}

export type MyDispatch = FixedDispatch<Action<ALL_ACTIONS>>;

type ReduxActionRet = (dispatch: MyDispatch, getState: GetRootState) => void;

type ReduxActionType = (cb: (dispatch: MyDispatch, context: FakeContextType, rootState: RootState, getState: () => RootState) => void | Promise<void>) => ReduxActionRet;

export const reduxAction: ReduxActionType = cb => async (dispatch, getState) => {
  const rootState = getState();
  const context = getFakeContext(getState());
  await cb(dispatch, context, rootState, getState);
};

export const persistor = persistStore(store);

export default store;
