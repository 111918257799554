import React from 'react';
import {Component} from '@lib/components';
import {RootState} from '@store/root';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import Map from '@organisms/Map';
import FullScreenContainer from '@layouts/FullScreenContainer';

type OwnProps = {
  children: any;
};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {};

class MapView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <FullScreenContainer>
        <Map />
      </FullScreenContainer>
    );
  }
}

// Redux connect
const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(MapView);
