import Constants from 'expo-constants';
import BaseConfiguration from './config.base';

// Load dynamic config by release channel
let LocalConfiguration;
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
  LocalConfiguration = require(`./config.${Constants.manifest.extra.env}`).default;
} catch (e) {
  LocalConfiguration = {};
}

const Config: typeof BaseConfiguration = {
  ...BaseConfiguration,
  ...LocalConfiguration,
};

export type ConfigType = typeof BaseConfiguration;

export default Config;
