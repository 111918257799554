const commonStyle = {
  margin: 'auto',
};

const sizeItem = {
  small: '10px',
  default: '12px',
  large: '14px',
};

const sizeContainer = {
  small: '24px',
  default: '30px',
  large: '36px',
};

const borderRadiusContainerSize = {
  small: '12px',
  default: '15px',
  large: '18px',
};

export {commonStyle, sizeItem, sizeContainer, borderRadiusContainerSize};
