import {USER_ACTIONS} from '@store/actionTypes';

export type userForm = {
  name: string;
  surname: string;
  _id: string;
  role: number;
  language: string;
  token: string;
};

export function setUser(user: userForm) {
  return {
    type: USER_ACTIONS.SET_USER as const,
    user,
  };
}

export function resetUserInformation() {
  return {
    type: USER_ACTIONS.RESET_USER as const,
  };
}

export type USER_ACTIONS_TYPES = ReturnType<typeof setUser> | ReturnType<typeof resetUserInformation>;
