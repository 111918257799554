import React from 'react';

import {Component} from '@lib_components';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import {StyleSheet, View, Text, Image, ImageStyle, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import {Button, createStyles, StepIconProps, Theme} from '@material-ui/core';

type Props = {
  selectedPhones: (phones: any) => void;
  phones: Array<string>;
};

type State = {
  phones: Array<string>;
  phone: string;
  errorPhone: boolean;
  phoneEmpty: boolean;
  files: any;
};

// Component
export default class MultiplePhoneSelect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phones: [],
      phone: '',
      errorPhone: false,
      phoneEmpty: false,
      files: null,
    };
  }

  componentDidMount() {
    if (this.props.phones.length > 0) {
      this.setState(prevState => ({...prevState, phones: this.props.phones}));
    }
  }

  handleDelete(data: string) {
    this.state.phones.splice(this.state.phones.indexOf(data), 1);
    this.setState(prevState => ({...prevState, phones: prevState.phones}));
  }

  _renderPhones() {
    return this.state.phones.map(data => (
      <Chip
        avatar={<Avatar style={{backgroundColor: '#cd5360', borderColor: '#cd5360', color: 'black'}}>{data.substring(0, 1).toUpperCase()}</Avatar>}
        label={data}
        onDelete={() => this.handleDelete(data)}
      />
    ));
  }

  phoneValidate(value: string) {
    if (value === '') return true;
    const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3})(?: *x(\d+))?\s*$/;
    return pattern.test(value);
  }

  find(phone: string) {
    return this.state.phones.find(value => phone === value);
  }

  addChip(phonesFile: string[] | null = null) {
    const phones = phonesFile || this.state.phone.split(' ').join(',').split(',');
    console.log(phones);
    for (const phone of phones) {
      if (this.phoneValidate(phone) && phone !== '' && !this.find(phone)) {
        this.state.phones.push(phone);
      }
    }
    this.setState(() => ({phone: ''}));
    this.setState(prevState => ({files: null}));
    this.props.selectedPhones(this.state.phones);
    this.empty();
  }

  empty() {
    this.setState(prevState => ({phoneEmpty: prevState.phones.length < 2}));
  }

  rules(value: string) {
    this.setState(prevState => ({errorPhone: !this.phoneValidate(value)}));
  }

  async pickImage(e: any, vm: any) {
    const {files} = e.target;
    vm.setState((prevState: any) => ({...prevState, files}));
    this.readFile(files);
  }

  readFile(files: any) {
    const reader = new FileReader();
    reader.onload = r => {
      const textFile = r.target?.result;
      const phones = textFile
        ?.toString()
        .split('\n')
        .map(phone => phone.trim());
      this.addChip(phones);
    };
    reader.readAsText(files[0]);
  }

  render() {
    return (
      <View>
        <Paper style={{boxShadow: 'none', padding: 0, alignSelf: 'center'}} component="ul">
          {this._renderPhones()}
        </Paper>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <TextField
              value={this.state.phone}
              label=""
              required
              error={this.state.phoneEmpty}
              onChange={e => {
                // @ts-ignore
                const {value} = e.target;
                this.empty();
                this.setState(prevState => ({...prevState, phone: value}));
              }}
              helperText="Introdueix un mínim de dos participants, per exemple 555222777."
              onKeyPress={event => event.charCode == 13 && this.addChip()}
            />
          </View>

          <View style={{backgroundColor: '#cd5360', marginLeft: 12, marginTop: 20, borderRadius: 10}}>
            <form>
              <label htmlFor="input">
                <View>
                  <TouchableOpacity style={{flexDirection: 'row', height: 35, alignItems: 'center'}}>
                    <input style={{color: 'transparent', display: 'none'}} type="file" accept=".csv,.txt" id="input" onChange={(event: any) => this.pickImage(event, this)} />
                    <Icon name="upload" size={20} color="white" style={{marginRight: 10, marginLeft: 10, marginTop: 20, marginBottom: 20}} />
                  </TouchableOpacity>
                </View>
              </label>
            </form>
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  chip: {
    margin: 10,
  },
});
