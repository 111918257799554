import {USER_ACTIONS_TYPES} from '@reducers/user/actions';
import {USER_ACTIONS} from '@store/actionTypes';

// State Type
type ReducerState = Readonly<{
  user: {
    name: string;
    surname: string;
    _id: string;
    role: number;
    language: string;
    token: string;
  };
}>;

// Initial state
const initialState: ReducerState = {
  user: {
    name: '',
    surname: '',
    _id: '',
    role: 1,
    language: '',
    token: '',
  },
};

// Actions that modify the state
export default (stateIn: ReducerState, action: USER_ACTIONS_TYPES): ReducerState => {
  const state = stateIn || initialState;
  switch (action.type) {
    case USER_ACTIONS.SET_USER:
      return {...state, user: action.user};
  }
  return state;
};
