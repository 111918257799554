import * as React from 'react';
import {SCREENS} from '@/navigations/screens';

let isReadyRef = false;

export const navigationRef: React.RefObject<any> = React.createRef();

const setReadyRef = (r: boolean) => {
  isReadyRef = r;
};

const navigate = (name: SCREENS, params?: any) => {
  if (isReadyRef && navigationRef.current) {
    navigationRef.current.navigate(name, params);
  }
};

const goBack = () => {
  if (isReadyRef && navigationRef.current) {
    navigationRef.current.goBack();
  }
};

export default {
  navigate,
  goBack,
  setReadyRef,
};
