import {TextStyle} from 'react-native';
import {scaleFont} from './mixins';

// FONT SIZE
const FONT_SIZE_70 = scaleFont(70);
const FONT_SIZE_50 = scaleFont(50);
const FONT_SIZE_30 = scaleFont(30);
const FONT_SIZE_20 = scaleFont(20);
const FONT_SIZE_18 = scaleFont(18);

// LINE HEIGHT
const LINE_HEIGHT_58 = scaleFont(58);
const LINE_HEIGHT_50 = scaleFont(50);
const LINE_HEIGHT_40 = scaleFont(40);
const LINE_HEIGHT_30 = scaleFont(30);
const LINE_HEIGHT_20 = scaleFont(20);
const LINE_HEIGHT_15 = scaleFont(15);

// Public

export const Text: TextStyle = {
  fontSize: FONT_SIZE_18,
  lineHeight: LINE_HEIGHT_15,
};

export const H1: TextStyle = {
  fontWeight: 'bold',
  fontSize: FONT_SIZE_70,
  lineHeight: LINE_HEIGHT_58,
};

export const H2: TextStyle = {
  fontWeight: 'bold',
  fontSize: FONT_SIZE_50,
  lineHeight: LINE_HEIGHT_50,
};

export const H3: TextStyle = {
  fontWeight: 'bold',
  fontSize: FONT_SIZE_30,
  lineHeight: LINE_HEIGHT_40,
};

export const H4: TextStyle = {
  fontWeight: 'bold',
  fontSize: FONT_SIZE_20,
  lineHeight: LINE_HEIGHT_30,
};

export const H5: TextStyle = {
  fontWeight: 'bold',
  fontSize: FONT_SIZE_18,
  lineHeight: LINE_HEIGHT_20,
};
