import React from 'react';
import {Provider} from 'react-redux';
import I18n from 'redux-i18n';
import {NavigationContainer} from '@react-navigation/native';
import {translations} from './src/locales/translations';
import store, {persistor} from './src/store/root';
import './web/global.css';
import MainLayout from './src/layouts/MainLayout';
import {PersistStore} from './src/lib/components/PersistStore';
import NavigationService, {navigationRef} from './src/navigations/NavigationService';
import MainNavigator from './src/navigations/MainNavigator';

type Props = {};

const linking = {
  prefixes: [],
  enabled: true,
};

export default function App(props: Props) {
  React.useEffect(
    () => () => {
      NavigationService.setReadyRef(false);
    },
    []
  );

  return (
    <Provider store={store}>
      <PersistStore persistor={persistor}>
        <I18n translations={translations} initialLang="es">
          <MainLayout>
            <NavigationContainer
              linking={linking}
              ref={navigationRef}
              onReady={() => {
                NavigationService.setReadyRef(true);
              }}>
              <MainNavigator />
            </NavigationContainer>
          </MainLayout>
        </I18n>
      </PersistStore>
    </Provider>
  );
}
