import React, {Component} from 'react';
import {View, Text, Dimensions} from 'react-native';
import Emitter from '@utils/Emitter';
import Icon from '@mdi/react';
import {mdiInformationOutline} from '@mdi/js';
import SemipolarLoading from './SemipolarLoading';

type Props = {
  loadingText?: string;
};

type State = {};

export default class Loading extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Emitter.on('show-loading', (params: any) => {
      console.log('HELLOUUU');
    });
  }

  render() {
    return (
      <View>
        <SemipolarLoading color="#cd5360" size="large" speed={1.8} />
        <View
          style={{
            width: 'max-context',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#F87171',
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 20,
            marginTop: 20,
          }}>
          <View>
            <Icon path={mdiInformationOutline} color="white" size={1.3} />
          </View>
          <View style={{marginLeft: 20}}>
            <Text style={{color: 'white', fontSize: 20, marginLeft: Dimensions.get('window').width > 500 ? 50 : 10}}>
              {' '}
              {this.props.loadingText ? <Text>{this.props.loadingText}</Text> : <Text>Creating votation...</Text>}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}
