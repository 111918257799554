import React, {Component} from 'react';
import {View, Text, Dimensions} from 'react-native';
import {Snackbar} from 'react-native-paper';
import Emitter from '@utils/Emitter';

type Props = {};

type State = {
  show: boolean;
  backgroundColor: string;
  colorText: string;
  message: string;
  type: string;
};

class SnackBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
      backgroundColor: '#34D399',
      colorText: 'white',
      message: 'Benvingut a Vote-Vote!',
      type: 'success',
    };
  }

  componentDidMount() {
    Emitter.on('show-snackbar', (params: any) => {
      let colorSnackBar = '';
      switch (params.color) {
        case 'success':
          colorSnackBar = '#34D399';
          break;
        case 'warning':
          colorSnackBar = '#F59E0B';
          break;
        case 'danger':
          colorSnackBar = '#EF4444';
          break;
      }
      this.setState(prevState => ({...prevState, backgroundColor: colorSnackBar, message: params.message, show: true, type: params.color}));
    });
  }

  render() {
    return (
      <View>
        <Snackbar
          visible={this.state.show}
          onDismiss={() => {
            this.setState(prevState => ({...prevState, show: false}));
          }}
          duration={5000}
          theme={{
            colors: {
              onSurface: this.state.backgroundColor,
              surface: this.state.colorText,
            },
          }}
          wrapperStyle={{
            position: 'absolute',
            right: 0,
            left: 0,
            bottom: 25,
            margin: 'auto',
            width: 'max-content',
            maxWidth: Dimensions.get('window').width,
          }}
          style={{borderRadius: 18, paddingRight: 20}}>
          <View style={{flexDirection: 'row'}}>
            <Text style={{paddingTop: 10, marginTop: 5, fontSize: 18, fontFamily: 'Barlow-SemiBold', marginLeft: 5}}>{this.state.message}</Text>
          </View>
          <Text style={{position: 'absolute', right: 10, top: 5, color: 'white', fontSize: 15}} onPress={() => this.setState(prevState => ({...prevState, show: false}))}>
            x
          </Text>
        </Snackbar>
      </View>
    );
  }
}

export default SnackBar;
