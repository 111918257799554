import React from 'react';
import {Component} from '@lib_components';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {RootState} from '@store/root';
import FullScreenContainer from '@layouts/FullScreenContainer';
import {Dimensions, StyleSheet, Text, View, Image} from 'react-native';
import {Parallax} from 'react-parallax';
import {mdiChartLineVariant, mdiCloud, mdiFlash, mdiVote} from '@mdi/js';
import Icon from '@mdi/react';
import {Button} from '@atoms';
import {SCREENS} from '@navigations/screens';

// Typing
type OwnProps = {};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {};

// Component
class HomeScreen extends Component<Props, State> {
  getHeight() {
    return Dimensions.get('window').width > 500 ? 120 : 110;
  }

  getMargins() {
    return Dimensions.get('window').width > 500 ? 100 : 20;
  }

  render() {
    return (
      <FullScreenContainer style={{backgroundColor: 'white', margin: 0}}>
        <View style={{display: 'flex', flexDirection: 'column'}}>
          <View style={{flex: 1, height: 600}}>
            <Parallax bgImage={require('@assets/images/home-vote.jpg')} style={{height: 500}} bgImageStyle={{top: '-50%'}} strength={450}>
              <div style={{display: 'flex', height: 600}}>
                <View style={styles.containerContent}>
                  <View>
                    <Text style={[styles.textStyles, {fontSize: 40}]}>Benvingut a Vote-Vote!</Text>
                  </View>
                  <View style={{marginTop: 10}}>
                    <Text style={[styles.textStyles, {fontSize: 18}]}>Crear una votació ara!</Text>
                  </View>
                  <Button
                    style={{borderRadius: '50%', backgroundColor: '#FF6169', padding: 20, marginTop: 20}}
                    onClick={() => this.props.navigation.navigate(SCREENS.CreateVote)}>
                    <Icon path={mdiVote} size={1.3} horizontal vertical rotate={180} color="white" />
                  </Button>
                </View>
              </div>
            </Parallax>
          </View>
          <View style={{flex: 1, flexDirection: 'column', marginTop: 50, marginBottom: 50}}>
            <View style={{alignItems: 'center'}}>
              <View>
                <Text style={{fontSize: 27}}>La millor forma de votar</Text>
              </View>
              <View>
                <Text style={{fontSize: 19}}>Mai ha estat tan fàcil</Text>
              </View>
            </View>
            <View style={{flexWrap: 'wrap', flexDirection: 'row', paddingTop: 20, marginLeft: 20, marginRight: 20, marginTop: 50}}>
              <View style={[styles.containers]}>
                <Icon path={mdiCloud} size={2} horizontal vertical rotate={180} color="#5AB3F2" />
                <Text style={{marginTop: 20, fontSize: 22}}>En línia</Text>
                <View style={{padding: 20}}>
                  <Text style={{textAlign: 'center', lineHeight: 20}}>
                    Podeu fer tot el procés de vot allà on sigueu. Fàcil, ràpid, còmode i sense necessitat de descarregar cap aplicació.
                  </Text>
                </View>
              </View>
              <View style={styles.containers}>
                <Icon path={mdiFlash} size={2} horizontal vertical rotate={180} color="#5AB3F2" />
                <Text style={{marginTop: 20, fontSize: 22}}>Anima a votar!</Text>
                <View style={{padding: 20}}>
                  <Text style={{textAlign: 'center', lineHeight: 20}}>Emet el teu vot i anima qui encara no ho ha fet a participar.</Text>
                </View>
              </View>
              <View style={styles.containers}>
                <Icon path={mdiChartLineVariant} size={2} horizontal vertical rotate={180} color="#5AB3F2" />
                <Text style={{marginTop: 20, fontSize: 22}}>Incentiva</Text>
                <View style={{padding: 20}}>
                  <Text style={{textAlign: 'center', lineHeight: 20}}>Un nou model de compromís que augmenta el percentatge de participació.</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </FullScreenContainer>
    );
  }
}

// Redux connect

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const styles = StyleSheet.create({
  containerContent: {
    backgroundColor: 'transparent',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 100,
  },
  textStyles: {
    color: 'white',
    textAlign: 'center',
  },
  containers: {
    backgroundColor: '#FDFDFD',
    minWidth: 150,
    flex: 1,
    margin: 10,
    borderRadius: 10,
    alignItems: 'center',
  },
});

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(HomeScreen);
