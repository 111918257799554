import React, {Component} from 'react';
import {SCREENS} from '@/navigations/screens';
import {createStackNavigator, StackNavigationOptions} from '@react-navigation/stack';
import Logo from '@atoms/Logo';
import HomeScreen from '@scenes/HomeScreen';
import AdminScreen from '@scenes/AdminScreen';
import VoteScreen from '@scenes/VoteScreen';
import VotePage from '@scenes/VotePage';
import {TouchableOpacity, View} from 'react-native';
import ResultsScreen from '@scenes/ResultsScreen';
import CreateVote from '@scenes/CreateVote';
import NavigationService from '@navigations/NavigationService';
import PostCreationScreen from '@scenes/PostCreationScreen';
import PublicResults from '@scenes/PublicResultsScreen';
import Map from '@scenes/MapView';
const Stack = createStackNavigator();

const NavigationOptions: StackNavigationOptions = {
  title: 'Vote-Vote',
  headerTitleAlign: 'center',
  headerTitleStyle: {},
  headerTransparent: false,
  gestureEnabled: false,
  headerStyle: {height: 100, borderBottomWidth: 0},
  headerTitle: '',
  headerLeft: () => (
    <View style={{padding: 20}}>
      <TouchableOpacity
        onPress={() => {
          NavigationService.navigate(SCREENS.Home);
        }}>
        <Logo />
      </TouchableOpacity>
    </View>
  ),
};

/**
 * All the tab navigators
 */

/**
 * Main Navigator
 */

type Props = {};

type State = {};

export default class MainNavigator extends Component<Props, State> {
  render() {
    return (
      <Stack.Navigator initialRouteName={SCREENS.Home} headerMode="screen" screenOptions={NavigationOptions}>
        <Stack.Screen name={SCREENS.Home} component={HomeScreen} />
        <Stack.Screen name={SCREENS.Results} component={ResultsScreen} />
        <Stack.Screen name={SCREENS.PublicResults} component={PublicResults} />
        <Stack.Screen name={SCREENS.Vote} component={VotePage} />
        <Stack.Screen name={SCREENS.Admin} component={AdminScreen} />
        <Stack.Screen name={SCREENS.Votacions} component={VoteScreen} />
        <Stack.Screen name={SCREENS.CreateVote} component={CreateVote} />
        <Stack.Screen name={SCREENS.PostCreationVotation} component={PostCreationScreen} />
        <Stack.Screen name={SCREENS.Map} component={Map} />
      </Stack.Navigator>
    );
  }
}
