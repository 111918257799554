import React, {Component} from 'react';
import {ScrollView, StyleSheet, Text, View, Image} from 'react-native';
import {Card, CardActions, Checkbox, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Select} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Icon from '@mdi/react';
import {Button} from '@atoms';
import ApiRepository from '@/repositories/ApiRepository';
import {SCREENS} from '@navigations/screens';
import Emitter from '@utils/Emitter';
import Loading from '@components/Loading';
import Constants from '@/constants';
import {mdiAlertCircle} from '@mdi/js';

type Props = {
  route: any;
  navigation: any;
};

type State = {
  questions: Array<any>;
  myAnswer: Array<any>;
  voteForOther: boolean;
  voteForOtherRemember: boolean;
  census: Array<any>;
  regularCensus: Array<any>;
  incentivatedCensus: Array<any>;
  censusSelected: string;
  otherAnswer: Array<any>;
  myEmail: string;
  phone: string;
  type: boolean;
  emailError: {
    error: boolean;
    message: string;
  };
  confirmation: boolean;
  vote: any;
  vote_answer: string;
  showLoading: boolean;
  showConfirmBtn: boolean;
  remind: boolean;
  location: {
    latitude: number;
    longitude: number;
  };
  votationLocation: {};
  geolocation: boolean;
};

class VotePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      questions: [],
      myAnswer: [],
      myEmail: '',
      phone: '',
      otherAnswer: [],
      voteForOther: false,
      voteForOtherRemember: false,
      remind: false,
      census: [],
      censusSelected: '',
      regularCensus: [],
      incentivatedCensus: [],
      type: false,
      emailError: {
        error: false,
        message: '',
      },
      confirmation: false,
      vote: null,
      vote_answer: '',
      showLoading: false,
      showConfirmBtn: true,
      location: {
        latitude: 0,
        longitude: 0,
      },
      votationLocation: {},
      geolocation: false,
    };
  }

  getUser(params: any) {
    ApiRepository.getUser(params.user_id)
      .then(res => {
        this.setState(prevState => ({...prevState, myEmail: res.data.user.email}));
      })
      .catch(err => console.log(err));
  }

  getLocation() {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({location: {latitude: position.coords.latitude, longitude: position.coords.longitude}});
        console.log({latitude: position.coords.latitude, longitude: position.coords.longitude});
      },
      function (error) {
        console.error(`Error Code = ${error.code} - ${error.message}`);
      }
    );
  }

  rad(d: any) {
    return (d * Math.PI) / 180.0;
  }

  outOfRange(userLocation: any, votationLocation: any) {
    const EARTH_RADIUS = 6378.137;
    const radLat1 = this.rad(userLocation.latitude);
    const radLat2 = this.rad(votationLocation.latitude);
    const a = radLat1 - radLat2;
    const b = this.rad(userLocation.longitude) - this.rad(votationLocation.longitude);
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s *= EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    console.log(s)
    return s > votationLocation.range;
  }

  locationActivated() {
    return this.state.location.latitude !== 0 && this.state.location.longitude !== 0;
  }

  componentDidMount() {
    const {params} = this.props.route;
    this.getLocation();
    if (params.votation_id) {
      ApiRepository.getVotation(params.votation_id)
        .then(res => {
          let incentivated = false;
          const censusInformation = res.data.votation.participants
            .map((participant: any) => participant._id !== params.user_id && {email: participant.email, id: participant._id})
            .filter(Boolean);
          const regularCensus = res.data.votation.regular_participants
            .map((regularparticipant: any) => regularparticipant._id !== params.user_id && {email: regularparticipant.email, id: regularparticipant._id})
            .filter(Boolean);
          const incentivatedCensus = res.data.votation.incentivated_participants
            .map((incentivedParticipant: any) => incentivedParticipant._id !== params.user_id && {email: incentivedParticipant.email, id: incentivedParticipant._id})
            .filter(Boolean);
          incentivated = res.data.votation.incentivated_participants.filter((item: {_id: any}) => item._id === params.user_id).length > 0;
          const otherAnswer = [];
          const myAnswer = [];
          for (const question of res.data.votation.questions) {
            const answerBlank = question.answers.find((o: {value: string}) => o.value === 'Vot en blanc');
            otherAnswer[question._id] = answerBlank._id;
            myAnswer[question._id] = answerBlank._id;
          }
          let census = [];
          if (params.type != Constants.TYPES.NO_CENSUS && params.type != Constants.TYPES.GEOLOCATED) {
            census = incentivatedCensus.length > 0 ? incentivatedCensus[0].id : censusInformation[0].id;
          }
          this.setState({
            questions: res.data.votation.questions,
            census: censusInformation,
            regularCensus,
            incentivatedCensus,
            censusSelected: census,
            type: incentivated,
            otherAnswer,
            myAnswer,
            remind: res.data.votation.reminder,
            votationLocation: res.data.votation.location,
            geolocation: res.data.votation.location.geolocation,
          });
        })
        .catch(err => console.log(err));

      this.getUser(params);
    } else if (params.vote_id && params.user_id) {
      ApiRepository.getVote(params.vote_id)
        .then(res => {
          const {myAnswer} = this.state;
          for (const answerV of res.data.vote.answer) {
            myAnswer[answerV.question] = answerV.answer;
          }
          console.log(res.data.vote.votation.questions);
          this.setState({vote: res.data.vote, questions: res.data.vote.votation.questions, myAnswer, showConfirmBtn: res.data.vote.answer !== undefined});
        })
        .catch(err => console.log(err));

      this.getUser(params);
      this.setState(prevState => ({...prevState, confirmation: true}));
    }
  }

  _selectRemember() {
    const answers = this.state.otherAnswer;
    for (const question of this.state.questions) {
      if (!this.state.voteForOtherRemember) {
        answers[question._id] = 'R';
      } else {
        const answerBlank = question.answers.find((o: {value: string}) => o.value === 'Vot en blanc');
        answers[question._id] = answerBlank._id;
      }
    }
    this.setState(prevState => ({...prevState, otherAnswer: answers, voteForOtherRemember: !prevState.voteForOtherRemember}));
  }

  _handleChangeAnswer(e: any, other: boolean, id: any) {
    const {value} = e.target;
    if (other) {
      const answer = this.state.otherAnswer;
      answer[id] = value;
      this.setState(prevState => ({...prevState, otherAnswer: answer}));
    } else {
      const answer = this.state.myAnswer;
      answer[id] = value;
      this.setState(prevState => ({...prevState, myAnswer: answer}));
    }
  }

  _renderAnswers(question: any) {
    return (
      <View>
        <RadioGroup value={this.state.myAnswer[question._id]} onChange={e => this._handleChangeAnswer(e, false, question._id)}>
          {question.answers.map((answer: any, index: any) => (
            <Text style={{fontSize: 10}} key={index}>
              <FormControlLabel value={answer._id} control={<Radio />} label={answer.value} />
            </Text>
          ))}
        </RadioGroup>
      </View>
    );
  }

  _renderAnswersOther(question: any) {
    return (
      <View>
        <RadioGroup value={this.state.otherAnswer[question._id]} onChange={e => this._handleChangeAnswer(e, true, question._id)}>
          {question.answers.map((answer: any, index: any) => (
            <Text style={{fontSize: 10}} key={index}>
              <FormControlLabel value={answer._id} control={<Radio />} label={answer.value} />
            </Text>
          ))}
          <Text style={{display: 'none'}}>
            <FormControlLabel value="R" control={<Radio />} label="Recordatori" />
          </Text>
        </RadioGroup>
      </View>
    );
  }

  _handleChangeCensus(e: any) {
    const {value} = e.target;
    this.setState(prevState => ({...prevState, censusSelected: value}));
  }

  _renderVoteForOther() {
    return (
      <View>
        <Text style={{fontSize: 15}}>Seleccioneu un correu electrònic del cens</Text>
        <View style={{marginTop: 15, marginBottom: 15}}>
          <FormControl>
            <InputLabel>Other email</InputLabel>
            <Select native defaultValue={this.state.censusSelected} onChange={e => this._handleChangeCensus(e)}>
              {this.state.incentivatedCensus.map((person, index) => (
                <option key={index} value={person.id}>
                  {person.email}
                </option>
              ))}
            </Select>
          </FormControl>
        </View>
      </View>
    );
  }

  handleSubmitVote() {
    this.setState(prevState => ({...prevState, showLoading: true}));
    const myAnswers = [];
    const otherAnswers = [];
    for (const [key, value] of Object.entries(this.state.myAnswer)) {
      myAnswers.push({question: key, answer: value});
    }
    for (const [key, value] of Object.entries(this.state.otherAnswer)) {
      otherAnswers.push({question: key, answer: value});
    }
    ApiRepository.vote(
      this.props.route.params.votation_id,
      myAnswers,
      this.props.route.params.type == Constants.TYPES.PHONE ? this.state.phone : this.props.route.params.user_id,
      this.state.voteForOther ? this.state.censusSelected : '',
      otherAnswers,
      this.props.route.params.token,
      this.props.route.params.type
    )
      .then(() => {
        Emitter.emit('show-snackbar', {message: "S'ha votat amb èxit!", color: 'success'});
        this.props.navigation.navigate(SCREENS.Home);
      })
      .catch(err => {
        Emitter.emit('show-snackbar', {message: err.response.data.message, color: 'danger'});
      })
      .finally(() => {
        this.setState(prevState => ({...prevState, showLoading: false}));
      });
  }

  _hasEmailError(email: string) {
    // if (email === '') return true;
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
  }

  _hasPhoneError(phone: string) {
    if (phone === '') return true;
    const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{4})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return pattern.test(phone);
  }

  submitConfirm() {
    ApiRepository.confirm_vote(this.state.vote._id, this.state.vote.voter._id)
      .then(() => {
        Emitter.emit('show-snackbar', {message: "S'ha confirmat la resposta de manera satisfactoria!", color: 'success'});
        this.props.navigation.navigate(SCREENS.Home);
      })
      .catch(err => console.log(err));
  }

  submitChangeAnswer() {
    const myAnswers = [];
    for (const [key, value] of Object.entries(this.state.myAnswer)) {
      myAnswers.push({question: key, answer: value});
    }
    ApiRepository.confirm_vote(this.state.vote._id, this.state.vote.voter._id, myAnswers)
      .then(() => {
        Emitter.emit('show-snackbar', {message: "S'ha canviat la resposta de manera satisfactoria!", color: 'success'});
        this.props.navigation.navigate(SCREENS.Home);
      })
      .catch(err => console.log(err));
  }

  renderConfirmationVote() {
    return (
      this.state.vote && (
        <View>
          <Card style={{marginLeft: 20, marginRight: 20, marginTop: 20, padding: 20}} elevation={1}>
            <View style={{margin: 8}}>
              <View>
                <Text style={styles.title}>{this.state.vote?.votation?.question}</Text>
              </View>
              <View style={{marginTop: 20}}>
                <Text>{this.state.vote_answer}</Text>
                {this.state.vote_answer ? (
                  <Text style={{fontSize: 16}}>{`La persona amb el següent correu electrònic: ${this.state.vote?.voter?.email} ha votat per vostè.`} </Text>
                ) : (
                  <Text style={{fontSize: 16}}>{`La persona amb el següent correu electrònic: ${this.state.vote?.voter?.email} ha enviat un recordatori de votar.`} </Text>
                )}
              </View>
              <View style={{marginTop: 20}}>
                <Text style={{fontSize: 15, fontWeight: 'bold'}}>Podeu veure la resposta seleccionada, si no esteu d&apos;acord, la podeu canviar.</Text>
                <View style={{marginTop: 10, marginLeft: 10}}>
                  {this.state.questions.map((question: any, index: any) => (
                    <View key={index}>
                      <View>
                        <Text style={styles.title}>{question.question}</Text>
                      </View>
                      <View style={{marginTop: 20}}>{this._renderAnswers(question)}</View>
                    </View>
                  ))}
                </View>
              </View>
              <View style={{marginTop: 20}}>
                <Text style={{fontSize: 16}}>Confirmes la resposta?</Text>
                <View style={{flexDirection: 'row'}}>
                  {this.state.showConfirmBtn && (
                    <Button
                      style={{width: 'max-content', paddingLeft: 20, paddingRight: 20, marginTop: 20, marginBottom: 10, backgroundColor: 'green'}}
                      onClick={() => this.submitChangeAnswer()}>
                      Confirmar
                    </Button>
                  )}
                </View>
              </View>
            </View>
          </Card>
        </View>
      )
    );
  }

  render() {
    // @ts-ignore
    return (
      <ScrollView style={{backgroundColor: 'white', margin: 0}}>
        {this.props.route.params.type == Constants.TYPES.PHONE && (
          <TextField
            label="Introdueix el teu identificador"
            style={{marginLeft: 20, marginRight: 20, marginTop: 20, maxWidth: '-moz-available'}}
            placeholder="ex: 657883149 o exemple@gmail.com"
            value={this.state.phone}
            fullWidth
            error={this.state.emailError.error}
            helperText={this.state.emailError.message}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              // @ts-ignore
              const {value} = e.target;
              if (this._hasPhoneError(value) && this._hasEmailError(value)) {
                this.setState(prevState => ({
                  ...prevState,
                  emailError: {error: true, message: 'Format incorrecte'},
                }));
              } else {
                this.setState(prevState => ({
                  ...prevState,
                  phone: value,
                  emailError: {error: false, message: ''},
                }));
              }
            }}
          />
        )}
        {!this.state.confirmation ? (
          <Card style={{marginLeft: 20, marginRight: 20, marginTop: 20, padding: 20}} elevation={1}>
            {!this.state.showLoading ? (
              <View style={{margin: 8}}>
                {this.props.route.votation_id && (
                  <TextField
                    label="El teu correu electrònic"
                    style={{marginTop: 20}}
                    placeholder="example@example.com"
                    value={this.state.myEmail}
                    fullWidth
                    error={this.state.emailError.error}
                    helperText={this.state.emailError.message}
                    disabled
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      // @ts-ignore
                      const {value} = e.target;
                      if (!this._hasEmailError(value)) {
                        this.setState(prevState => ({
                          ...prevState,
                          emailError: {error: true, message: 'Mal format de correu electrònic'},
                        }));
                      } else {
                        this.setState(prevState => ({
                          ...prevState,
                          myEmail: value,
                          emailError: {error: false, message: ''},
                        }));
                      }
                    }}
                  />
                )}
                {this.state.questions.map((question: any, index: any) => (
                  <View key={index}>
                    <View>
                      <Text style={styles.title}>{question.question}</Text>
                    </View>
                    <View style={{marginTop: 20}}>{this._renderAnswers(question)}</View>
                  </View>
                ))}
                {this.state.type && (
                  <View style={{marginTop: 20}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.voteForOther}
                          onChange={() =>
                            this.setState(prevState => ({
                              ...prevState,
                              voteForOther: !prevState.voteForOther,
                            }))
                          }
                          style={{
                            fontSize: 20,
                          }}
                        />
                      }
                      label="Vota per algú altre"
                    />
                  </View>
                )}
                {this.state.voteForOther && this.state.incentivatedCensus.length > 0 && (
                  <View>
                    <View style={{marginTop: 20}}>{this._renderVoteForOther()}</View>

                    {this.state.remind && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.voteForOtherRemember}
                            onChange={() => this._selectRemember()}
                            style={{
                              fontSize: 20,
                            }}
                          />
                        }
                        label="Recordatori"
                      />
                    )}
                    {this.state.questions.map((question: any, index: any) => (
                      <View key={index}>
                        <View>
                          <Text style={styles.title}>{question.question}</Text>
                        </View>
                        <View style={{marginTop: 20}}>{this._renderAnswersOther(question)}</View>
                      </View>
                    ))}
                  </View>
                )}
                {this.state.voteForOther && this.state.incentivatedCensus.length == 0 && <Text> No pots animar a votar a ningú més.</Text>}
                <View style={{display: 'flex', alignSelf: 'left', marginTop: 40}}>
                  <CardActions>
                    <Button
                      style={{width: 'max-content', paddingLeft: 20, paddingRight: 20, marginTop: 20, marginBottom: 10}}
                      color="secondary"
                      onClick={() => this.handleSubmitVote()}
                      disabled={this.state.geolocation && (!this.locationActivated() || this.outOfRange(this.state.location, this.state.votationLocation))}>
                      Vote
                    </Button>
                    {!this.locationActivated() && this.state.geolocation && (
                      <div
                        style={{
                          width: 'max-content',
                          color: '#721c24',
                          backgroundColor: '#f8d7da',
                          borderColor: '#f5c6cb',
                          position: 'relative',
                          padding: '0.75rem 1.25rem',
                          marginBottom: '1rem',
                          marginLeft: 8,
                          border: '1px solid transparent',
                          borderRadius: `${0.25}rem`,
                        }}>
                        <Icon path={mdiAlertCircle} size={1.5} color="#F07070" style={{marginRight: 10}} />
                        <Text>Necessitem accés a la teva ubicació</Text>
                      </div>
                    )}
                    {this.locationActivated() && this.state.geolocation && this.outOfRange(this.state.location, this.state.votationLocation) && (
                      <div
                        style={{
                          width: 'max-content',
                          color: '#721c24',
                          backgroundColor: '#f8d7da',
                          borderColor: '#f5c6cb',
                          position: 'relative',
                          padding: '0.75rem 1.25rem',
                          marginBottom: '1rem',
                          marginLeft: 8,
                          border: '1px solid transparent',
                          borderRadius: `${0.25}rem`,
                        }}>
                        <Icon path={mdiAlertCircle} size={1.5} color="#F07070" style={{marginRight: 10}} />
                        <Text>Estas massa lluny per poder votar</Text>
                      </div>
                    )}
                  </CardActions>
                </View>
              </View>
            ) : (
              <View style={{display: 'flex', alignSelf: 'center', marginTop: 40}}>
                <Loading loadingText="Voting..." />
              </View>
            )}
          </Card>
        ) : (
          this.renderConfirmationVote()
        )}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: '500',
  },
});

export default VotePage;
