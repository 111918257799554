import React from 'react';
import {Component} from '@lib_components';

type Props = {};

type State = {};

export default class Logo extends Component<Props, State> {
  render() {
    return (
      <div>
        <img src={require('@assets/images/logo.png')} alt="Logo Vote-Vote" height={55} />
      </div>
    );
  }
}
