import React from 'react';
import {Component} from '@lib_components';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {RootState} from '@store/root';
import {Text, View, StyleSheet, ImageBackground} from 'react-native';
import Login from '@components/Login';

// Typing
type OwnProps = {};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {};

// Component
class AdminScreen extends Component<Props, State> {
  render() {
    return (
      <ImageBackground source={{uri: require('../assets/images/home-vote.jpg')}} style={{height: '100%'}}>
        <View style={{display: 'flex', flexDirection: 'column'}}>
          <Text style={{textAlign: 'center', fontSize: 25}}>Benvingut a Vote-Vote, inicieu sessió per continuar:</Text>
          <Login />
        </View>
      </ImageBackground>
    );
  }
}

// Redux connect

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const styles = StyleSheet.create({});

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(AdminScreen);
