import React from 'react';
import {StyleSheet, Text as NativeText} from 'react-native';
import {Component} from '@lib_components';
import {Colors, Spacing, Typography} from '@/styles';

type Props = {white?: boolean};

type State = {};

const Styles = StyleSheet.create({
  base: {
    ...Typography.H5,
    ...Spacing.H5,
    color: Colors.normalTextColors.H5,
  },
});

export default class H5 extends Component<Props, State> {
  _root: NativeText | null = null;

  setNativeProps(nativeProps: Record<string, unknown>) {
    if (this._root) {
      this._root.setNativeProps(nativeProps);
    }
  }

  render() {
    const WhiteStyle = this.props.white ? {color: Colors.white} : {};
    return (
      <NativeText
        style={[Styles.base, WhiteStyle, this.props.style]}
        ref={component => {
          this._root = component;
          return this._root;
        }}>
        {this.props.children}
      </NativeText>
    );
  }
}
