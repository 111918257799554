import React, {RefObject} from 'react';
import {Component} from '@lib_components';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {RootState} from '@store/root';
import FullScreenContainer from '@layouts/FullScreenContainer';
import clsx from 'clsx';
import {StyleSheet, Text, View} from 'react-native';
import MultipleEmailSelect from '@components/MultipleEmailSelect';
import MultiplePhoneSelect from '@components/MultiplePhoneSelect';
import TextField from '@material-ui/core/TextField';
import {Button, createStyles, StepIconProps, Theme} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import AnswersComponent from '@components/AnswersComponent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ApiRepository from '@/repositories/ApiRepository';
import Emitter from '@utils/Emitter';
import Abstract from '@components/Abstract';
import moment from 'moment';
import Loading from '@components/Loading';
import {SCREENS} from '@navigations/screens';
import StepConnector from '@material-ui/core/StepConnector';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import StepButton from '@material-ui/core/StepButton';
import Map from '@organisms/Map';
// @ts-ignore
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from 'react-native-simple-radio-button';
// @ts-ignore
import {mdiPencilCircle} from '@mdi/js';
import Icon from '@mdi/react';
import Constants from '@/constants';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Typing

type OwnProps = {};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {
  question: string;
  mail: string;
  password: string;
  host: string;
  port: number;
  errorQuest: boolean;
  error: boolean;
  errorAddResponse: boolean;
  errorEditResponse: boolean;
  errorDate: boolean;
  errorRadio: boolean;
  newResponse: string;
  responses: Array<any>;
  emails: Array<any>;
  phones: Array<any>;
  show: boolean;
  // date: Date;
  dateStart: string;
  timeStart: string;
  dateEnd: string;
  timeEnd: string;
  votationType: number;
  activeStep: number;
  prevActiveStep: number;
  chboxIncentivized: boolean;
  chboxUniversal: boolean;
  chboxFollowLink: boolean;
  files: any;
  showLoading: boolean;
  reminder: boolean;
  multiQuestions: Array<{
    question: string;
    answers: Array<any>;
  }>;
  latitude: number;
  longitude: number;
  radio: number;
  chboxLocation: boolean;
  chboxEnviament: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: 300,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    inputRoot: {
      paddingTop: 5,
      fontSize: 18,
      color: 'gray',
      marginBottom: 20,
    },
    labelRoot: {
      fontSize: 20,
      color: 'primary',
      '&$labelFocused': {
        color: 'secondary',
      },
    },
  })
);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient(121deg, rgba(191,191,191,1) 0%, rgba(247,247,247,1) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient(121deg, rgba(191,191,191,1) 0%, rgba(247,247,247,1) 100%)',
    },
  },
})(StepConnector);

const radio_types = [
  {label: 'Correu electrònic', value: Constants.TYPES.EMAIL},
  {label: 'Identificador', value: Constants.TYPES.PHONE},
  {label: 'Enllaç de votació', value: Constants.TYPES.NO_CENSUS},
  {label: 'Geolocalitzada', value: Constants.TYPES.GEOLOCATED},
];

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: 'gray',
    zIndex: 1,
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: 'white',
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const {active, completed} = props;

  const getIcon = (index: React.ReactNode) => {
    switch (index) {
      case 1:
        return completed ? <Icon path={mdiPencilCircle} color="#cd5360" size={1.7} /> : '1';
      case 2:
        return completed ? <Icon path={mdiPencilCircle} color="#cd5360" size={1.3} /> : '2';
      case 3:
        return completed ? <Icon path={mdiPencilCircle} color="#cd5360" size={1.3} /> : '3';
      case 4:
        return completed ? <Icon path={mdiPencilCircle} color="#cd5360" size={1.3} /> : '4';
      case 5:
        return completed ? <Icon path={mdiPencilCircle} color="#cd5360" size={1.3} /> : '5';
      default:
        return completed ? <Icon path={mdiPencilCircle} color="#cd5360" size={1.3} /> : '6';
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {getIcon(props.icon)}
    </div>
  );
}

// Component
class CreateVote extends Component<Props, State> {
  getEmails: RefObject<MultipleEmailSelect> = React.createRef();
  getPhones: RefObject<MultiplePhoneSelect> = React.createRef();
  getAnswers: RefObject<AnswersComponent> = React.createRef();

  constructor(props: Props) {
    super(props);
    // @ts-ignore
    this.state = {
      emails: [],
      phones: [],
      responses: [],
      errorQuest: false,
      error: false,
      errorAddResponse: false,
      question: '',
      newResponse: '',
      mail: '',
      password: '',
      host: '',
      port: 0,
      errorEditResponse: false,
      show: true,
      // date: new Date(),
      dateStart: moment(new Date()).format('YYYY-MM-DD'),
      timeStart: moment(new Date()).format('HH:mm'),
      dateEnd: moment(new Date(new Date().getTime() + 86400000 * 7)).format('YYYY-MM-DD'),
      timeEnd: moment(new Date()).format('HH:mm'),
      votationType: 0,
      activeStep: 0,
      prevActiveStep: 0,
      chboxIncentivized: true,
      chboxUniversal: false,
      chboxFollowLink: false,
      files: null,
      showLoading: false,
      errorDate: false,
      errorRadio: false,
      reminder: false,
      multiQuestions: [],
      latitude: -91,
      longitude: -181,
      radio: 0,
      chboxLocation: false,
      chboxEnviament: false,
    };
  }

  onDateChange(dates: [any, any]) {
    const [start, end] = dates;
    this.setState(prevState => ({...prevState, startDate: start, endDate: end}));
  }

  handleNext() {
    if (this.state.activeStep < 4) {
      this.setState(prevState => ({...prevState, activeStep: prevState.activeStep + 1}));
    } else {
      this.setState(prevState => ({...prevState, showLoading: true}));
      let census = [];
      let votationTypeRet = this.state.votationType;
      if (this.state.votationType === Constants.TYPES.EMAIL) {
        census = this.state.emails;
      } else if (this.state.votationType === Constants.TYPES.PHONE) {
        census = this.state.phones;
      }
      if (this.state.votationType === Constants.TYPES.EMAIL && this.state.chboxUniversal) {
        votationTypeRet = Constants.TYPES.PHONE;
      }
      const location = {longitude: this.state.longitude, latitude: this.state.latitude, range: this.state.radio, geolocation: this.state.longitude >-91 && this.state.latitude>-181};
      ApiRepository.create(
        this.state.question,
        this.state.responses,
        census,
        this.state.chboxIncentivized,
        `${this.state.dateStart}T${this.state.timeStart}`,
        `${this.state.dateEnd}T${this.state.timeEnd}`,
        this.state.chboxFollowLink,
        this.state.reminder,
        this.state.multiQuestions,
        votationTypeRet,
        this.state.mail,
        this.state.password,
        this.state.host,
        this.state.port,
        location
      )
        .then(res => {
          this.setState(prevState => ({...prevState, showLoading: false}));
          this.props.navigation.navigate(SCREENS.PostCreationVotation, {votation_id: res.data.votation._id, results_id: res.data.results._id, type: votationTypeRet});
        })
        .catch(e => {
          console.log(e);
          Emitter.emit('show-snackbar', {message: 'Alguna cosa ha anat malament!', color: 'danger'});
          this.props.navigation.navigate(SCREENS.Home);
        });
    }
  }

  handleBack() {
    this.setState(prevState => ({...prevState, activeStep: prevState.activeStep - 1}));
  }

  validate() {
    switch (this.state.activeStep) {
      case 0:
        if (this.getEmails.current) {
          this.getEmails.current.addChip();
        }
        if (this.getPhones.current) {
          this.getPhones.current.addChip();
        }
        if (this.state.votationType === 0) {
          // is email
          return this.state.emails.length >= 2;
        }
        if (this.state.votationType === 1) {
          // is phone
          return this.state.phones.length >= 2;
        }
        if (this.state.votationType === 3) {
          return this.errorLocation();
        }
        return this.state.votationType === 2;
      case 1:
        if (this.getAnswers.current) {
          this.getAnswers.current.addQuestion();
        }
        return this.validateMultiQuestions();
      case 2:
        return !this.errorDate();
      case 3: // parse file
        console.log(`Location: ${this.state.chboxLocation}`);
        return true;
      case 4: // parse file
        return true;
      default:
        return false;
    }
  }

  validateMultiQuestions() {
    let valid = this.state.multiQuestions.length > 0;
    let i = 0;
    while (valid && i < this.state.multiQuestions.length) {
      valid = this.state.multiQuestions[i].question !== '';
      if (valid) {
        valid = this.state.multiQuestions[i].answers.length >= 2;
      }
      i++;
    }
    return valid;
  }

  errorDate() {
    return new Date(`${this.state.dateStart.toString()}T${this.state.timeStart}`).getTime() >= new Date(`${this.state.dateEnd}T${this.state.timeEnd}`).getTime();
  }

  errorMail() {
    console.log(this.state.mail);
    const mySplit = this.state.mail.split('@');
    return mySplit[1] !== 'gmail.com' || this.state.mail === '';
  }

  testEmail() {
    console.log('test');
    ApiRepository.testEmail(this.state.mail, this.state.password, this.state.host, this.state.port)
      .then(() => {
        Emitter.emit('show-snackbar', {message: 'Mail enviat correctament!', color: 'success'});
        return true;
      })
      .catch(e => {
        console.log(e);
        Emitter.emit('show-snackbar', {message: 'Alguna cosa ha anat malament!', color: 'danger'});
        return false;
      });
  }

  errorLocation() {
    if (this.state.radio <= 0) Emitter.emit('show-snackbar', {message: 'Falta afegir radi', color: 'danger'});
    else if (this.state.latitude === -91 && this.state.longitude ===-181) Emitter.emit('show-snackbar', {message: 'Falta seleccionar geolocalització', color: 'danger'});
    return this.state.radio > 0 && this.state.latitude > -90 && this.state.longitude > -180;
  }

  renderButons() {
    return (
      <div style={{margin: 15, textAlign: 'center'}}>
        <div>
          {this.state.activeStep !== 0 && <Button onClick={() => this.handleBack()}>Enrere</Button>}
          <Button style={{backgroundColor: '#cd5360', color: 'white'}} onClick={() => this.validate() && this.handleNext()}>
            {this.state.activeStep === 5 - 1 ? 'Confirmar' : 'Següent'}
          </Button>
        </div>
      </div>
    );
  }

  showMap() {
    return (
      <View>
        <Map
          latitude={this.state.latitude}
          longitude={this.state.longitude}
          selectedLocation={(latitude, longitude) => {
            this.setState(prevState => ({...prevState, latitude}));
            this.setState(prevState => ({...prevState, longitude}));
          }}
        />
        <View style={{flex: 1}}>
          <TextField
            defaultValue={this.state.radio}
            onChange={e => {
              const {value} = e.target;
              this.setState(prevState => ({...prevState, radio: Number(value)}));
            }}
            helperText="Introdueix radi de participació (Km). Només  els participants situats dins el radi de participació podran votar."
          />
          <Text>Radi: {this.state.radio}</Text>
          <Text>Latitud: {this.state.latitude === -91 ? 0 : this.state.latitude }</Text>
          <Text>Longitud: {this.state.longitude === -181 ? 0: this.state.longitude}</Text>
        </View>
      </View>
    );
  }

  _handleStep(step: number) {
    this.validate();
    this.setState({activeStep: step});
  }

  render() {
    // @ts-ignore
    return (
      <FullScreenContainer>
        {!this.state.showLoading ? (
          <Stepper activeStep={this.state.activeStep} orientation="vertical" connector={<ColorlibConnector />}>
            <Step title="Participants">
              <StepButton onClick={() => this._handleStep(0)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>Selecciona el mètode de participació</StepLabel>
              </StepButton>
              <StepContent>
                <RadioForm
                  style={{display: 'flex', justifyContent: 'center'}}
                  radio_props={radio_types}
                  initial={this.state.votationType}
                  formHorizontal
                  labelHorizontal={false}
                  buttonColor="#F9B00D"
                  selectedButtonColor="#F9B00D"
                  onPress={(votationType: any) => {
                    this.setState(prevState => ({...prevState, votationType}));
                  }}
                />
                {this.state.votationType === 0 && (
                  <MultipleEmailSelect
                    selectedEmails={emails => {
                      this.setState(prevState => ({...prevState, emails}));
                    }}
                    emails={this.state.emails}
                    ref={this.getEmails}
                  />
                )}
                {this.state.votationType === 1 && (
                  <MultiplePhoneSelect
                    selectedPhones={phones => {
                      this.setState(prevState => ({...prevState, phones}));
                    }}
                    phones={this.state.phones}
                    ref={this.getPhones}
                  />
                )}
                {this.state.votationType === 3 && this.showMap()}
                {this.renderButons()}
              </StepContent>
            </Step>
            <Step title="Preguntes">
              <StepButton onClick={() => this._handleStep(1)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>Crea la pregunta/preguntes</StepLabel>
              </StepButton>
              <StepContent>
                <AnswersComponent
                  selectedQueries={(question, answers) => {
                    this.setState(prevState => ({...prevState, question}));
                    this.setState(prevState => ({...prevState, responses: answers}));
                  }}
                  ref={this.getAnswers}
                  multiQueries={queries => {
                    this.setState(prevState => ({...prevState, multiQuestions: queries}));
                  }}
                  multiQuestions={this.state.multiQuestions}
                />
                {this.renderButons()}
              </StepContent>
            </Step>
            <Step title="Periode">
              <StepButton onClick={() => this._handleStep(2)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>Selecciona el període obert de participació</StepLabel>
              </StepButton>
              <StepContent>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', alignItems: 'center', maxWidth: 300}}>
                  <Text style={{flex: 3}}>Inici:</Text>
                  <TextField
                    style={{flex: 5}}
                    id="date"
                    type="date"
                    error={this.errorDate()}
                    helperText={this.errorDate() ? 'Introduïu una data més gran que avui, si us plau.' : ''}
                    onChange={date => {
                      const {value} = date.target;
                      this.setState(prevState => ({...prevState, dateStart: value}));
                    }}
                    //   defaultValue="2017-05-24"
                    value={this.state.dateStart}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    style={{flex: 3}}
                    id="date"
                    type="time"
                    onChange={date => {
                      const {value} = date.target;
                      this.setState(prevState => ({...prevState, timeStart: value}));
                    }}
                    //   defaultValue="2017-05-24"
                    value={this.state.timeStart}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </View>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', alignItems: 'center', maxWidth: 300}}>
                  <Text style={{flex: 3}}>Fi:</Text>
                  <TextField
                    style={{flex: 5}}
                    id="date"
                    type="date"
                    error={this.errorDate()}
                    helperText={this.errorDate() ? 'Introduïu una data més gran que avui, si us plau.' : ''}
                    onChange={date => {
                      // @ts-ignore
                      const {value} = date.target;
                      this.setState(prevState => ({...prevState, dateEnd: value}));
                      this.setState(prevState => ({...prevState, errorDate: this.errorDate()}));
                    }}
                    //   defaultValue="2017-05-24"
                    value={moment(this.state.dateEnd).format('YYYY-MM-DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    style={{flex: 3}}
                    id="date"
                    type="time"
                    onChange={date => {
                      const {value} = date.target;
                      this.setState(prevState => ({...prevState, timeEnd: value}));
                    }}
                    value={this.state.timeEnd}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </View>
                {this.renderButons()}
              </StepContent>
            </Step>
            <Step title="Enviament">
              <StepButton onClick={() => this._handleStep(4)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>Enviament</StepLabel>
              </StepButton>
              <StepContent>
                <View>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={this.state.chboxEnviament}
                        onChange={() => {
                          this.setState(prevState => ({...prevState, chboxEnviament: !prevState.chboxEnviament}));
                        }}
                      />
                    }
                    label="Modifica l'adreça de correu per a la gestió d'enviaments"
                  />
                  {this.state.chboxEnviament && (
                    <View>
                      <View style={{flex: 1}}>
                        <StepLabel>Introdueix nova adreça de correu:</StepLabel>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', alignItems: 'center', maxWidth: 300}}>
                          <TextField
                            defaultValue={this.state.mail}
                            style={{flex: 7}}
                            id="email"
                            type="text"
                            label="Email"
                            onChange={e => {
                              // @ts-ignore
                              const {value} = e.target;
                              this.setState(prevState => ({...prevState, mail: value}));
                            }}
                            error={this.errorMail()}
                            helperText={this.errorMail() ? 'Introduïu un correu' : ''}
                          />
                        </View>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', alignItems: 'center', maxWidth: 300}}>
                          <TextField
                            defaultValue={this.state.password}
                            style={{flex: 7}}
                            id="password"
                            type="password"
                            label="Paraula de pas"
                            onChange={e => {
                              // @ts-ignore
                              const {value} = e.target;
                              this.setState(prevState => ({...prevState, password: value}));
                            }}
                          />
                        </View>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', alignItems: 'center', maxWidth: 300}}>
                          <TextField
                            defaultValue={this.state.host}
                            style={{flex: 7}}
                            id="host"
                            type="text"
                            label="Host"
                            onChange={e => {
                              // @ts-ignore
                              const {value} = e.target;
                              this.setState(prevState => ({...prevState, host: value}));
                            }}
                          />
                        </View>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', alignItems: 'center', maxWidth: 300}}>
                          <TextField
                            defaultValue={this.state.port}
                            style={{flex: 7}}
                            id="port"
                            type="number"
                            label="Port"
                            onChange={e => {
                              // @ts-ignore
                              const {value} = e.target;
                              // @ts-ignore
                              this.setState(prevState => ({...prevState, port: value}));
                            }}
                          />
                        </View>
                        <Button style={{backgroundColor: '#cd5360', color: 'white', marginTop: 20, maxWidth: 300, flexDirection: 'row'}} onClick={() => this.testEmail()}>
                          Test Email
                        </Button>
                      </View>
                    </View>
                  )}
                  {this.renderButons()}
                </View>
              </StepContent>
            </Step>
            <Step title="Confirm">
              <StepButton onClick={() => this._handleStep(5)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>Confirmar</StepLabel>
              </StepButton>
              <StepContent>
                <Abstract
                  responses={this.state.responses}
                  emails={this.state.emails}
                  phones={this.state.phones}
                  question={this.state.question}
                  dateEnd={`${this.state.dateEnd}T${this.state.timeEnd}`}
                  dateStart={`${this.state.dateStart}T${this.state.timeStart}`}
                  incentivized={this.state.chboxIncentivized}
                  chboxFollowLink={this.state.chboxFollowLink}
                  questions={this.state.multiQuestions}
                />
                <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex'}}>
                  {this.state.votationType === 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.chboxIncentivized}
                          onChange={() => {
                            this.setState(prevState => ({...prevState, chboxIncentivized: !prevState.chboxIncentivized}));
                          }}
                        />
                      }
                      label="Anima a votar!"
                    />
                  )}
                </View>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex'}}>
                  {this.state.votationType === 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.chboxFollowLink}
                          onChange={() => {
                            this.setState(prevState => ({...prevState, chboxFollowLink: !prevState.chboxFollowLink}));
                          }}
                        />
                      }
                      label="Adjunta enllaç de seguiment amb el correu de confirmació"
                    />
                  )}
                </View>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex'}}>
                  {this.state.votationType === 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.chboxUniversal}
                          onChange={() => {
                            this.setState(prevState => ({...prevState, chboxUniversal: !prevState.chboxUniversal}));
                          }}
                        />
                      }
                      label="Mostra l'enllaç de votació"
                    />
                  )}
                </View>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex'}}>
                  {this.state.votationType == Constants.TYPES.EMAIL && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.reminder}
                          onChange={() => {
                            this.setState(prevState => ({...prevState, reminder: !prevState.reminder}));
                          }}
                        />
                      }
                      label="Recordatori"
                    />
                  )}
                </View>
                {this.state.votationType=== Constants.TYPES.GEOLOCATED && (
                  <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex'}}>
                    <Text style={{fontSize: 19, marginLeft: 20}}>Participació geolocalitzada</Text>
                  </View>
                )}

                {this.state.chboxLocation && (
                  <View>
                    <div style={{marginLeft: 20}}>
                      <ListItem button style={{padding: 0}}>
                        <ListItemText primary="Latitud" secondary={this.state.latitude} />
                      </ListItem>
                      <ListItem button style={{padding: 0}}>
                        <ListItemText primary="Longitud" secondary={this.state.longitude} />
                      </ListItem>
                      <ListItem button style={{padding: 0}}>
                        <ListItemText primary="Radi" secondary={`${this.state.radio} km`} />
                      </ListItem>
                    </div>
                  </View>
                )}

                {this.state.chboxEnviament && (
                  <View style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex'}}>
                    <Text style={{fontSize: 19, marginLeft: 20}}>Dades correu enviament</Text>
                  </View>
                )}

                {this.state.chboxEnviament && (
                  <View>
                    <div style={{marginLeft: 20}}>
                      <ListItem button style={{padding: 0}}>
                        <ListItemText primary="Email" secondary={this.state.mail} />
                      </ListItem>
                      <ListItem button style={{padding: 0}}>
                        <ListItemText primary="Host" secondary={this.state.host} />
                      </ListItem>
                      <ListItem button style={{padding: 0}}>
                        <ListItemText primary="Port" secondary={this.state.port} />
                      </ListItem>
                    </div>
                  </View>
                )}
                {this.renderButons()}
              </StepContent>
            </Step>
          </Stepper>
        ) : (
          <View style={{display: 'flex', alignSelf: 'center', marginTop: 40}}>
            <Loading />
          </View>
        )}
      </FullScreenContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 10,
    backgroundColor: '#ecf0f1',
    padding: 8,
  },
  date: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '60%',
  },
  datePart: {
    width: 100,
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: '200',
    marginBottom: 5,
  },
  digit: {
    fontSize: 20,
  },
  root: {
    width: '100%',
  },
  button: {
    marginTop: 5,
    marginRight: 5,
  },
  actionsContainer: {
    marginBottom: 5,
  },
  resetContainer: {
    padding: 5,
  },
  textField: {
    marginLeft: 5,
    marginRight: 5,
    width: 200,
  },
});
// Redux connect

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(CreateVote);
