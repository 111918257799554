import React from 'react';
import {RootState} from '@store/root';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import Component from '@lib/components/Component';
import {Dimensions, StyleSheet, Text, View} from 'react-native';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MapView from 'react-native-web-maps';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Script from 'react-load-script';
import EasyScroll from '@components/EasyScroll';

type OwnProps = {};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

// type Props = ReduxProps & DispatchProps & OwnProps;

type Props = {
  selectedLocation: (latitude: any, longitude: any) => void;
  latitude: any;
  longitude: any;
};

type State = {
  mapLoaded: boolean;
  mark: {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
  };
  isVisible: boolean;
  visible: boolean;
  latitude: any;
  longitude: any;
};

class Map extends Component<Props, State> {
  map: any;

  initialRegion = {
    latitude: 41.981338,
    longitude: 2.821315,
    latitudeDelta: 0.5822,
    longitudeDelta: 0.5321,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      mark: {
        latitude: this.props.latitude,
        longitude: this.props.longitude,
        latitudeDelta: 0,
        longitudeDelta: 0,
      },
      visible: false,
      mapLoaded: false,
      isVisible: false,
    };
  }

  handleScriptCreate = () => {
    // console.log('handleScriptCreate');
  };

  handleScriptError = () => {
    // console.log('handleScriptError');
  };

  handleScriptLoad = () => {
    // console.log('handleScriptLoad');
    this.setState({mapLoaded: true});
  };

  onMapPress(e: any) {
    this.setState(prevState => ({...prevState, mark: {latitude: e.latLng.lat(), longitude: e.latLng.lng(), latitudeDelta: 0, longitudeDelta: 0}}));
    // this.props.latitude = e.latLng.lat();
    // this.props.longitude = e.latLng.lng();
    this.props.selectedLocation(e.latLng.lat(),e.latLng.lng());
  }

  render() {
    const mapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC_y2yJ3VS5I50wc6UtAxtKVH94atoexvk`;
    return (
      <View>
        <Script url={mapUrl} onCreate={this.handleScriptCreate} onError={this.handleScriptError} onLoad={this.handleScriptLoad} />
        <View style={styles.mapContainer}>
          {this.state.mapLoaded && (
            <MapView
              ref={(component: any) => {
                this.map = component;
              }}
              initialRegion={this.initialRegion}
              options={{disableDefaultUI: true}}
              style={styles.map}
              onRegionChangeComplete={(region: any) => this.setState(prevState => ({...prevState, patata: region}))}
              onPress={(e: any) => this.onMapPress(e)}>
              <MapView.Marker coordinate={this.state.mark} />
            </MapView>
          )}
        </View>
      </View>
    );
  }
}

// Redux connect

// SELECTORS
const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({});

// ACTIONS
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Map);

const styles = StyleSheet.create({
  map: {
    width: Dimensions.get('window').width * 0.50,
    height: Dimensions.get('window').height * 0.50,
    margin: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mapContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomNavigationView: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    backgroundColor: '#fff',
    width: '100%',
    height: 250,
    justifyContent: 'center',
    alignItems: 'center',
  },
  latlng: {
    width: 200,
    alignItems: 'stretch',
  },
});
