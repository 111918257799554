import React from 'react';
import {Component} from '@lib_components';
import {Text, StyleSheet, TouchableHighlight, View, Dimensions} from 'react-native';
import NavigationService from '@navigations/NavigationService';
import {SCREENS} from '@navigations/screens';
import LocalRepository from '@/repositories/LocalRepository';
import {RootState} from '@store/root';
import {getUser} from '@reducers/user/selectors';
import {bindActionCreators, Dispatch} from 'redux';
import {resetUserInformation, setUser} from '@reducers/user/actions';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import AuthRepository from '@/repositories/AuthRepository';
import Emitter from '@utils/Emitter';
import {Button} from '@material-ui/core';

// Typing

type OwnProps = {
  // onClose: () => void;
  // onLogin: () => void;
};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {
  isDialogOpen: boolean;
  isLogged: boolean;
  user: {
    email: string;
    password: string;
  };
};

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDialogOpen: true,
      user: {
        email: '',
        password: '',
      },
      isLogged: false,
    };
  }

  componentDidMount() {
    this.isLogged().then();
  }

  async isLogged() {
    if (this.props.route && this.props.route.params) {
      this.validateUser(this.props.route.params);
    }
    const token = await LocalRepository.get('token');
    if (token) this.setState({isLogged: true});
    else this.setState({isLogged: false});
  }

  validateUser(token: string) {
    AuthRepository.validate(token)
      .then(response => {
        Emitter.emit('show-snackbar', {
          message: 'Heu estat validat, ara podeu accedir a la plataforma.',
          color: 'success',
        });
        if (response && response.data && response.data.token) {
          this.setSocket(response.data.user, response.data.token);
        }
      })
      .catch(err => {
        console.log('Error validateUser function');
      });
  }

  async setSocket(user: any, token: string) {
    try {
      await LocalRepository.set('token', token);
      this.props.setUser(user);
      const connectionSocket = await LocalRepository.get('connectionSocket');
      if (connectionSocket) {
        AuthRepository.saveSocket(connectionSocket).then();
      }
      Emitter.emit('show-snackbar', {message: 'Heu iniciat la sessió correctament!', color: 'success'});
      // this.props.onClose();
      this.forceUpdate();
    } catch (e) {
      Emitter.emit('show-snackbar', {message: 'Error en iniciar sessió!', color: 'danger'});
      console.log(e);
    }
  }

  async login() {
    AuthRepository.login(this.state.user.email, this.state.user.password)
      .then(async res => {
        this.setState({isLogged: true});
        this.setState({isDialogOpen: false});
        await this.setSocket(res.data.user, res.data.token);
        this.props.setUser(res.data.user);
        Emitter.emit('show-snackbar', {message: "S'ha iniciat sessió correctament!", color: 'success'});
        // this.props.onLogin();
        NavigationService.navigate(SCREENS.Votacions);
      })
      .catch(e => {
        console.log(e);
        Emitter.emit('show-snackbar', {message: 'Error en iniciar la sessió!', color: 'danger'});
      });
  }

  _handleOnchange(e: any, type: string) {
    const username = e.target.value;
    this.setState(prevState => ({user: {...prevState.user, [type]: username}}));
  }

  onClose() {
    this.setState(prevState => ({isDialogOpen: false}));
  }

  keypress() {
    console.log('press');
  }

  render() {
    return (
      <View style={{marginLeft: 15, marginRight: 15}}>
        <View style={{backgroundColor: 'white', borderRadius: 7, padding: 15, marginTop: 30, maxWidth: 600, alignSelf: 'center', width: '100%'}}>
          <Text style={{alignSelf: 'center', margin: 10, fontSize: 15}}>Inicia sessió per entrar a l&apos;àrea privada</Text>
          <View style={{flex: 1}}>
            <TextField
              autoFocus
              id="email"
              label="Correu electrònic"
              type="email"
              fullWidth
              style={{justifyContent: 'center', fontFamily: 'Barlow-SemiBold'}}
              onChange={e => {
                this._handleOnchange(e, 'email');
              }}
              onKeyPress={event => event.charCode === 13 && this.login()}
            />
            <TextField
              id="password"
              label="Contrassenya"
              type="password"
              fullWidth
              style={{fontFamily: 'Barlow-SemiBold'}}
              onChange={e => {
                this._handleOnchange(e, 'password');
              }}
              onKeyPress={event => event.charCode === 13 && this.login()}
            />
          </View>
          <Button style={{backgroundColor: '#cd5360', color: 'white', alignSelf: 'center', marginTop: 10, marginBottom: 10}} onClick={async () => await this.login()}>
            {this.context.t('LOGIN')}
          </Button>
        </View>
      </View>
    );
  }
}

// Redux connect

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetUserInformation,
      setUser,
    },
    dispatch
  );

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#cd5360',
    padding: 10,
    width: 150,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
  },
});

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Login);
