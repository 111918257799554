import Repository from './Repository';
import LocalRepository from './LocalRepository';

export default {
  async getMe(token = null) {
    try {
      if (token) {
        const response = await Repository.new().auth(token).get('/me');
        if (response && response.data) {
          return response.data.user;
        }
      } else if (LocalRepository.has('token')) {
        const response = await Repository.new().get('/me');
        if (response && response.data) {
          return response.data.user;
        }
      }
    } catch (ignored) {
      return null;
    }
    return null;
  },

  validate(token) {
    return Repository.new().post('/validate', {tokenValidated: token});
  },
  loginAsGuest() {
    return Repository.new().post('/login-as-guest', {});
  },
  saveSocket(socket) {
    return Repository.new().post('/save-socket', {socket});
  },
  login(email, pass) {
    return Repository.new().post('/login', {email, password: pass});
  },
  register(params) {
    return Repository.new().post('/register', {params});
  },
};
