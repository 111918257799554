import React, {RefObject} from 'react';
import {Component} from '@lib_components';

// @ts-ignore
import {StyleSheet, View, Text} from 'react-native';

import TextField from '@material-ui/core/TextField';
// @ts-ignore
import {mdiClose, mdiCheckboxMarked, mdiHelpCircle, mdiPlusBoxMultiple, mdiNoteEdit} from '@mdi/js';
import Icon from '@mdi/react';
import {IconButton, Button, Divider} from '@material-ui/core';

type Props = {
  selectedQueries: (question: any, responses: any) => void;
  multiQueries: (
    queries: Array<{
      question: string;
      answers: Array<any>;
    }>
  ) => void;
  multiQuestions: Array<any>;
};

type State = {
  question: string;
  errorQuest: boolean;
  error: boolean;
  errorAddResponse: boolean;
  errorEditResponse: boolean;
  newResponse: string;
  responses: Array<any>;
  colorIcon: string;
  questionArray: Array<{
    question: string;
    answers: Array<any>;
  }>;
};

// Component
export default class AnswersComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // @ts-ignore
    this.state = {
      responses: [],
      errorQuest: false,
      error: false,
      errorAddResponse: false,
      question: '',
      newResponse: '',
      errorEditResponse: false,
      colorIcon: 'grey',
      questionArray: [],
    };
  }

  componentDidMount() {
    this.setState(prevState => ({...prevState, questionArray: this.props.multiQuestions}));
  }

  onChange(e: any, type: string, index: number) {
    let value = '';
    if (e.target) {
      value = e.target.value;
    }
    switch (type) {
      case 'question':
        this.setState(() => ({errorQuest: value !== null && value.trim() === ''}));
        this.setState(prevState => ({...prevState, [type]: value, error: prevState.errorQuest}));
        break;
      case 'newResponse':
        this.setState(prevState => ({errorAddResponse: prevState.responses.length < 1}));
        this.setState(prevState => ({...prevState, [type]: value, error: prevState.errorQuest}));
        break;
      case 'resposes':
        this.setState(prevState => ({errorEditResponse: value !== null && value.trim() === ''}));
        if (value.trim() === '') this.state.responses.splice(index, 1);
        else this.state.responses[index] = value;
        this.setState(prevState => ({...prevState, responses: prevState.responses, error: prevState.errorQuest}));
        break;
    }
  }

  onChangeMultiQuestions(e: any, type: string, indexQuestion: number, index: number) {
    let value = '';
    if (e.target) {
      value = e.target.value;
    }
    switch (type) {
      case 'question':
        this.setState(() => ({errorQuest: value !== null && value.trim() === ''}));
        this.state.questionArray[indexQuestion].question = value;
        this.setState(prevState => ({...prevState, questionArray: prevState.questionArray, error: prevState.errorQuest}));
        break;
      case 'newResponse':
        this.setState(prevState => ({errorAddResponse: prevState.responses.length < 1}));
        this.setState(prevState => ({...prevState, [type]: value, error: prevState.errorQuest}));
        break;
      case 'resposes':
        this.setState(prevState => ({errorEditResponse: value !== null && value.trim() === ''}));
        if (value.trim() === '') this.state.responses.splice(index, 1);
        else this.state.questionArray[indexQuestion].answers[index] = value;
        this.setState(prevState => ({...prevState, questionArray: prevState.questionArray, error: prevState.errorQuest}));
        break;
    }
  }

  find(answer: string) {
    return this.state.responses.find(value => answer === value);
  }

  addResponse() {
    if (this.state.newResponse.trim() !== '' && !this.find(this.state.newResponse.trim())) {
      this.state.responses.push(this.state.newResponse.trim());
      this.setState(() => ({newResponse: ''}));
    }
  }

  multiDeleteResponse(response: any, i: number) {
    if (this.state.questionArray[i].answers.length > 2) {
      this.state.questionArray[i].answers.splice(this.state.responses.indexOf(response), 1);
      this.setState(prevState => ({...prevState, questionArray: prevState.questionArray}));
      this.props.multiQueries(this.state.questionArray);
    }
  }

  multiDeleteQuestion(i: number) {
    this.state.questionArray.splice(i, 1);
    this.setState(prevState => ({...prevState, questionArray: prevState.questionArray}));
    this.props.multiQueries(this.state.questionArray);
  }

  deleteResponse(response: any) {
    this.state.responses.splice(this.state.responses.indexOf(response), 1);
    this.setState(prevState => ({...prevState, responses: prevState.responses}));
  }

  existError() {
    this.setState(prevState => ({
      error: prevState.errorQuest || prevState.errorAddResponse,
    }));
  }

  _renderAnswers() {
    return this.state.responses.map((response, index) => (
      <View style={styles.inputStyle}>
        <Icon style={{alignSelf: 'center', marginRight: 15}} path={mdiCheckboxMarked} color={this.state.colorIcon} size={1} />
        <TextField
          style={{alignSelf: 'center', flex: 'auto'}}
          value={response}
          label="Editar resposta"
          required
          error={this.state.errorAddResponse}
          onChange={e => {
            this.onChange(e, 'resposes', index);
          }}
          helperText={this.state.errorAddResponse ? 'Introduïu respostes' : ''}
          InputProps={{
            endAdornment: (
              <IconButton
                style={{margin: 0, padding: 0}}
                onClick={() => {
                  this.deleteResponse(response);
                }}>
                <Icon path={mdiClose} color={this.state.colorIcon} size={1} />
              </IconButton>
            ),
          }}
        />
      </View>
    ));
  }

  addQuestion() {
    if (this.state.responses.length >= 2) {
      if (!this.state.questionArray.find(value => value.question === this.state.question)) {
        this.state.questionArray.push({
          question: this.state.question,
          answers: [...this.state.responses],
        });
        this.setState(prevState => ({...prevState, questionArray: prevState.questionArray}));
      }
      this.setState(prevState => ({...prevState, question: '', responses: [], newResponse: ''}));
      this.props.multiQueries(this.state.questionArray);
    }
  }

  showQuestions() {
    return this.state.questionArray.map((row, i) => (
      <View style={{marginTop: 10}}>
        <View style={styles.inputStyle}>
          <Icon style={{alignSelf: 'center', marginRight: 15}} color={this.state.colorIcon} path={mdiHelpCircle} size={1} />
          <TextField
            style={{alignSelf: 'center', flex: 'auto'}}
            label="Introduïu la pregunta"
            required
            onChange={e => {
              this.onChangeMultiQuestions(e, 'question', i, 0);
            }}
            value={row.question}
            error={this.state.errorQuest}
            helperText={this.state.errorQuest ? 'Introduïu la pregunta' : ''}
            InputProps={{
              endAdornment: (
                <IconButton
                  style={{margin: 0, padding: 0}}
                  onClick={() => {
                    this.multiDeleteQuestion(i);
                  }}>
                  <Icon path={mdiClose} color={this.state.colorIcon} size={1} />
                </IconButton>
              ),
            }}
          />
        </View>
        {row.answers.map((response, index) => (
          <View style={styles.inputStyle}>
            <Icon style={{alignSelf: 'center', marginRight: 15}} path={mdiCheckboxMarked} color={this.state.colorIcon} size={1} />
            <TextField
              style={{alignSelf: 'center', flex: 'auto'}}
              value={response}
              label="Editar resposta"
              required
              error={row.answers.length < 2}
              onChange={e => {
                this.onChangeMultiQuestions(e, 'resposes', i, index);
              }}
              helperText={this.state.errorAddResponse ? 'Introduïu respostes' : ''}
              InputProps={{
                endAdornment: (
                  <IconButton
                    style={{margin: 0, padding: 0}}
                    onClick={() => {
                      this.multiDeleteResponse(response, i);
                    }}>
                    <Icon path={mdiClose} color={this.state.colorIcon} size={1} />
                  </IconButton>
                ),
              }}
            />
          </View>
        ))}
        <Divider style={{background: '#cd5360', marginTop: 10, marginBottom: 10, height: 10}} />
      </View>
    ));
  }

  render() {
    return (
      <View>
        {this.showQuestions()}
        <View style={styles.inputStyle}>
          <Icon style={{alignSelf: 'center', marginRight: 15}} color={this.state.colorIcon} path={mdiHelpCircle} size={1} />
          <TextField
            style={{alignSelf: 'center', flex: 'auto'}}
            label="Introduïu la pregunta"
            required
            value={this.state.question}
            error={this.state.errorQuest}
            onChange={e => {
              this.onChange(e, 'question', 0);
            }}
            helperText={this.state.errorQuest ? 'Introduïu la pregunta' : ''}
          />
        </View>

        {this._renderAnswers()}
        <View style={styles.inputStyle}>
          <Icon style={{alignSelf: 'center', marginRight: 15}} path={mdiNoteEdit} color={this.state.colorIcon} size={1} />
          <TextField
            style={{alignSelf: 'center', flex: 'auto'}}
            label="Afegeix resposta"
            required
            value={this.state.newResponse}
            error={this.state.errorAddResponse}
            onChange={e => {
              this.onChange(e, 'newResponse', 0);
            }}
            onKeyPress={event => event.charCode === 13 && this.addResponse()}
            helperText={this.state.errorAddResponse ? 'Introduïu almenys dues respostes' : ''}
            InputProps={{
              endAdornment: (
                <IconButton style={{margin: 0, padding: 0}} onClick={() => this.addResponse()}>
                  <Icon color={this.state.colorIcon} path={mdiPlusBoxMultiple} size={1} />
                </IconButton>
              ),
            }}
          />
        </View>

        <Button
          style={{backgroundColor: '#cd5360', color: 'white', alignSelf: 'center', marginTop: 10}}
          onClick={() => {
            this.addQuestion();
          }}>
          Afegir Pregunta
        </Button>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputStyle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
  },
});
