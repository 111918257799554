const palette = {
  black: '#353535',
  black2: '#000000de',
  white: '#ffffff',
  green: '#F9D706', // color principal
  green2: '#BAA204',
  green3: '#9EC718', // success
  green4: '#FFF4A2', // segon groc
  green5: '#FAF4CD',
  gray: '#808080',
  gray2: '#abacae',
  gray3: '#f0f0f0',
  gray4: '#d8d8d8',
  graySeparator: '#979797',
  blue: '#243540',
  blue2: '#5a7e8c',
  blue3: '#93b7bf',
  orange: 'orange',
  yellow: '#FFC954', // warning
  red: '#ff2222',
  red2: '#FF6C54',
  red3: '#e13939',
  red4: '#ff3737',
  silver: '#abacae',
  bronze: '#d46d38',
  ocher: '#715C17',
};

const primary = palette.green;
const secondary = palette.gray;
const tertiary = palette.ocher;
const errorAlert = palette.red2;

// ACTIONS
const error = palette.red2;
const danger = palette.red2;
const warning = palette.red2;
const success = palette.green3;
const info = palette.blue3;

const background = palette.gray2;
const backgroundHeader = palette.gray2;

const {graySeparator} = palette;

const normalTextColors = {
  H1: palette.black,
  H2: palette.black,
  H3: palette.black,
  H4: palette.black,
  H5: palette.black,
  text: palette.black,
  link: palette.black,
  textInput: palette.black,
  checkBox: palette.black,
};

const alternativeTextColors = {
  H1: palette.ocher,
  H2: palette.ocher,
  H3: palette.ocher,
  H4: palette.ocher,
  H5: palette.ocher,
  text: palette.ocher,
  link: palette.ocher,
  showMore: palette.green,
  textInput: palette.white,
  checkBox: palette.ocher,
};

const {white, black, yellow, silver, bronze} = palette;

const disabled = palette.gray;

export {
  backgroundHeader,
  primary,
  secondary,
  tertiary,
    errorAlert,
  error,
  danger,
  warning,
  success,
  info,
  white,
  black,
  background,
  normalTextColors,
  alternativeTextColors,
  disabled,
  yellow,
  silver,
  bronze,
  graySeparator,
};
