import React from 'react';
import Config from '@config/config';
import {PersistGate} from 'redux-persist/integration/react';
import {Persistor} from 'redux-persist/es/types';
import LoadingContainer from '@layouts/LoadingContainer';

type Props = {
  persistor: Persistor;
};

export const PersistStore: React.FunctionComponent<Props> = props => {
  if (Config.persistStore) {
    return (
      <PersistGate persistor={props.persistor} loading={<LoadingContainer />}>
        {props.children}
      </PersistGate>
    );
  }
  return <>{props.children}</>;
};
