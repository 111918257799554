/**
 * All the strings MUST BE UNIQUE
 */

export enum USER_ACTIONS {
  SET_USER = 'USER/SET_USER',
  RESET_USER = 'USER/RESET_USER',
}
export enum SOCKET_ACTIONS {
  SET_SOCKET = 'SOCKET/SET_SOCKET',
}

export enum QUERY_FORM_ACTIONS {
  SET_FORM = 'QUERYINFO/SET_FORM',
}

export enum I18N_ACTIONS {
  SET_TRANSLATION_FUNCTION = 'I18N/SET_TRANSLATION_FUNCTION',
}

/**
 * All actions for common type
 */
export type ALL_ACTIONS = I18N_ACTIONS | USER_ACTIONS | QUERY_FORM_ACTIONS;

/**
 * All Reducers Names
 */
export enum REDUCER_NAMES {
  queryInfor = 'queryInfo',
  i18n = 'i18n',
  i18nState = 'i18nState',
}
