import React from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import {Component} from '@lib_components';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {RootState} from '@store/root';

import DataTable from 'react-data-table-component';
import ApiRepository from '@/repositories/ApiRepository';
import {Card, Chip} from '@material-ui/core';
// @ts-ignore
import {PieChart} from 'react-native-web-svg-charts';
import Icon from 'react-native-vector-icons/FontAwesome';
import Avatar from '@material-ui/core/Avatar';

// Typing

type OwnProps = {};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & DispatchProps & OwnProps;

type State = {
  title: string;
  totalVotes: number;
  data: any[];
  rows: any[];
  columns: any[];
  participation: number;
  cens: number;
  votes: number;
  prova: Array<{
    question: string;
    data: any[];
    rows: any[];
  }>;
};

// Component
class ResultsScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      prova: [],
      title: '',
      votes: 0,
      totalVotes: 0,
      data: [],
      rows: [],
      cens: 0,
      columns: [
        {
          name: <Text style={styles.column}>Resposta</Text>,
          selector: 'answer',
          sortable: true,
          wrap: true,
          style: {fontWeight: 'bold', fontSize: 20},
          center: true,
        },
        {
          name: <Text style={styles.column}>Vots</Text>,
          selector: 'votes',
          sortable: true,
          right: true,
          wrap: true,
          style: {fontSize: 15},
          center: true,
        },
        {
          name: <Text style={styles.column}>Vots incentivats</Text>,
          selector: 'delegatedvotes',
          sortable: true,
          right: true,
          wrap: true,
          style: {fontSize: 15},
          center: true,
        },
        {
          name: <Text style={styles.column}>Vots incentivats confirmats</Text>,
          selector: 'ConfirmedDelegatedVotes',
          sortable: true,
          right: true,
          wrap: true,
          style: {fontSize: 15},
          center: true,
        },
        {
          name: <Text style={styles.column}>% Vots incentivats confirmats</Text>,
          selector: 'ConfirmedDelegatedVotesPercentatege',
          sortable: true,
          right: true,
          wrap: true,
          style: {fontSize: 15},
          center: true,
        },
      ],
      participation: 0,
    };
  }

  componentDidMount() {
    // @ts-ignore
    ApiRepository.results(this.props.route.params.votation_id, this.props.route.params.results_id).then(res => {
      for (let i = 0; i < res.data.results.questions.length; i++) {
        this.setState((prevState: any) => ({
          ...prevState,
          title: res.data.results.votation.question,
        }));
        let votes = 0;
        const resData: {key: any; value: any; svg: {fill: string | number} | {fill: string}; arc: {padAngle: number}}[] = [];
        const rows: {
          id: number;
          answer: any;
          votes: number;
          delegatedvotes: number;
          ConfirmedDelegatedVotes: number;
          ConfirmedDelegatedVotesPercentatege: number;
        }[] = [];
        const datas = res.data.results.questions[i].answers;
        let total_votes = 0;
        let incentivated_votes = 0;
        let incentivated_votes_confirmed = 0;
        let incentivated_votes_percentage_confirmed = 0;
        console.log(datas)
        for (let i = 0; i < datas.length; i++) {
          const data = datas[i];
          const colorSeed = data._id;
          const colorBase = this.toHex(`${colorSeed}ijzhskdlasdfsdfjhkl`);
          resData.push({
            key: data.value,
            value: data.votes,
            svg: {fill: colorBase},
            arc: {padAngle: 0},
          });
          resData.push({
            key: `${data.value} incentivat`,
            value: data.delegated_votes_confirmed,
            svg: {fill: this.colorShade(colorBase, 40)},
            arc: {padAngle: 0},
          });
          votes += data.delegated_votes + data.votes;
          rows.push({
            id: i,
            answer: data.value,
            votes: data.votes,
            delegatedvotes: data.delegated_votes,
            ConfirmedDelegatedVotes: data.delegated_votes_confirmed,
            ConfirmedDelegatedVotesPercentatege:
              data.delegated_votes_confirmed > 0 ? parseInt((data.delegated_votes / data.delegated_votes_confirmed).toFixed(2), 10) * 100 : 0,
          });
          total_votes += data.votes;
          incentivated_votes += data.delegated_votes;
          incentivated_votes_confirmed += data.delegated_votes_confirmed;
          incentivated_votes_percentage_confirmed += rows[i].ConfirmedDelegatedVotesPercentatege;
        }
        rows.push({
          id: datas.length + 1,
          answer: 'TOTAL',
          votes: total_votes,
          delegatedvotes: incentivated_votes,
          ConfirmedDelegatedVotes: incentivated_votes_confirmed,
          ConfirmedDelegatedVotesPercentatege: incentivated_votes_percentage_confirmed,
        });
        const colorBase = this.toHex(`${resData.length + 1}ijzhskdlasdfsdfjhkl`);
        resData.push({
          key: 'Participants pendents',
          value: res.data.results.total_census - res.data.results.participations,
          svg: {fill: colorBase},
          arc: {padAngle: 0},
        });
        console.log(resData);
        this.setState((prevState: any) => ({
          ...prevState,
          data: resData,
          totalVotes: votes,
          participation: votes > 0 ? (votes / res.data.results.total_census) * 100 : 0,
          cens: res.data.results.total_census,
          votes: res.data.results.participations,
        }));

        this.state.prova.push({
          data: [...resData],
          rows: [...rows],
          question: res.data.results.questions[i].question,
        });
        this.setState(prevState => ({...prevState, prova: prevState.prova}));
      }
    });
  }

  colorShade(col: any, amt: number) {
    col = col.replace(/^#/, '');
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

    let [r, g, b] = col.match(/.{2}/g);
    [r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

    r = Math.max(Math.min(255, r), 0).toString(16);
    g = Math.max(Math.min(255, g), 0).toString(16);
    b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (r.length < 2 ? '0' : '') + r;
    const gg = (g.length < 2 ? '0' : '') + g;
    const bb = (b.length < 2 ? '0' : '') + b;

    return `#${rr}${gg}${bb}`;
  }

  toHex(str: string) {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      // eslint-disable-next-line no-bitwise
      hash &= hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (i * 8)) & 255;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
  }

  calculatePie(index: number) {
    const pieData = this.state.prova[index].data;
    const legend = [];
    for (let i = 0; i < pieData.length; i++) {
      if (pieData[i].value !== 0.9 && pieData[i].key !== '') {
        legend.push(
          <li style={{listStyle: 'none', marginLeft: 0, marginBottom: 2}}>
            <Chip
              style={{width: 250, justifyContent: 'left', margin: 5}}
              avatar={<Avatar style={{backgroundColor: pieData[i].svg.fill, borderColor: '#cd5360', color: 'black'}}>{pieData[i].value}</Avatar>}
              label={
                <Text>
                  {' '}
                  {pieData[i].key}({this.state.totalVotes > 0 ? parseFloat(String((pieData[i].value * 100) / this.state.cens)).toFixed(2) : 0}%)
                </Text>
              }
            />
          </li>
        );
      }
    }
    return legend;
  }

  _renderDataTables() {
    // @ts-ignore
    return this.state.prova.map((p, index) => (
      <View>
        <Card elevation={2} style={{marginBottom: 15, overflow: 'initial', padding: 10}}>
          <View style={styles.viewHeader}>
            <Icon name="clipboard" size={20} color="white" style={{marginRight: 15, marginLeft: 15, alignSelf: 'center'}} />
            <Text style={styles.titleHeader}>Resultats de la votació pregunta: {index + 1}</Text>
          </View>
          <View>
            <View style={{marginLeft:10}}>
              <Text style={{textAlign: 'left',fontWeight: 'bold', fontSize: 20}}>Pregunta: {p.question} </Text>
              <Text style={{fontWeight: 'bold', fontSize: 15}}>Participants pendents: {this.state.cens - this.state.votes} </Text>
              <Text style={{fontWeight: 'bold', fontSize: 15}}>Percentatge participació: {this.state.participation.toFixed(2)}% </Text>
            </View>
            <DataTable
              columns={this.state.columns}
              data={p.rows}
            />
          </View>
        </Card>
        <Card elevation={2} style={{alignItems: 'center', overflow: 'initial', padding: 10, textAlign: 'center'}}>
          <View style={styles.viewHeader}>
            <Icon name="users" size={20} color="white" style={{marginRight: 15, marginLeft: 15, alignSelf: 'center'}} />
            <Text style={styles.titleHeader}>Informació participants</Text>
          </View>
          <View>
            <Text style={{marginLeft:10, textAlign:'left',fontWeight: 'bold', fontSize: 20}}>Pregunta: {p.question}</Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <PieChart style={{height: 500, width: 500, flex: 1}} data={p.data} sort={(a: {key: string}, b: {key: any}) => null} />
            <View style={{flex: 1, alignItems: 'center'}}>
              <div style={{marginTop: 10, marginBottom: 20}}>
                <ul style={{margin: 0, marginBottom: 5, padding: 0, float: 'left', listStyle: 'none'}}>{this.calculatePie(index)}</ul>
              </div>
            </View>
          </View>
        </Card>
      </View>
    ));
  }

  render() {
    return (
      <ScrollView style={{overflow: 'scroll'}} scrollEnabled>
        {this._renderDataTables()}
      </ScrollView>
    );
  }
}

// Redux connect

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

// Styles
const styles = StyleSheet.create({
  viewHeader: {
    flexDirection: 'row',
    backgroundColor: '#cd5360',
    marginBottom: 10,
    marginTop: 10,
  },
  titleHeader: {
    color: 'white',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 20,
  },
  column: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  title: {
    textAlign: 'left',
    marginBottom: 5,
    fontWeight: 'bold',
  },
  ul: {
    margin: 0,
    marginBottom: 5,
    padding: 0,
    float: 'left',
    listStyle: 'none',
  },
  li: {
    listStyle: 'none',
    marginLeft: 0,
    lineHeight: 18,
    marginBottom: 2,
  },
  span: {
    height: 5,
  },
});

export default connect<ReduxProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(ResultsScreen);
